import './TopCollections.css';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { Carousel } from '@trendyol-js/react-carousel';

function TopCollections({ collectionsData, selectedPeriod, setSelectedPeriod }) {

  const isMobile = window.innerWidth <= 768;

  const formatVolume = (volume) => {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
      formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
      formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', '') + ' ₳'; // Remove trailing .0 if present
  };

  return (
    <div className="top-collections" style={{ marginTop: '50px' }}>
      <h2>
        🔥Top NFT Collections
        <div className="time-period-buttons">
          {/* <button className={selectedPeriod === '24h' ? 'selected' : ''} onClick={() => setSelectedPeriod('24h')}>24H</button>
          <button className={selectedPeriod === '7d' ? 'selected' : ''} onClick={() => setSelectedPeriod('7d')}>7D</button> */}
          <button className={selectedPeriod === '30d' ? 'selected' : ''} onClick={() => setSelectedPeriod('30d')}>30D</button>
          <button className={selectedPeriod === 'all' ? 'selected' : ''} onClick={() => setSelectedPeriod('all')}>ALL</button>
        </div>
      </h2>
      <div className="carousel-container">
        {collectionsData.length > 0 ? (
          <Carousel
            dynamic={true}
            key={selectedPeriod}
            show={isMobile ? 1 : 4}
            slide={isMobile ? 1 : 4}
            swiping={true}
            swipeOn={isMobile ? 0.0875 : 0.25}
            infinite={false}
            responsive={false}
            leftArrow={<ArrowLeft className="arrow-style left" />}
            rightArrow={<ArrowRight className="arrow-style right" />}
          >
            {collectionsData.map((collection, index) => {
              const thumbnailUrl = typeof collection.thumbnail === 'string'
                ? collection.thumbnail.replace(/^ipfs:\/\/(ipfs\/)?/, 'https://ipfs.io/ipfs/')
                : '';

              return (
                <div
                  key={collection.id || index} // Use collection.id if available, otherwise use index
                  className="collection-card"
                  style={{ backgroundImage: `url(${thumbnailUrl})` }}
                  onClick={() => window.open(collection.link, '_blank')}
                >
                  <img
                    src={thumbnailUrl}
                    alt="Collection Thumbnail"
                    style={{ display: 'none' }} // Make the img tag invisible
                    onError={(e) => {
                      e.target.parentElement.style.backgroundImage = 'url(/img-missing.png)';
                    }}
                  />
                  <div className="collection-info">
                    <h3 className="title-with-border">{collection.name}</h3>
                    <div className="info-container">
                      <marquee className="animated-info title-with-border">
                        <p>Rank #{index + 1} • Volume: {formatVolume(collection.volume)} • Floor Price: {formatVolume(collection.floor_price)} • Owners: {collection.total_owners}</p>
                      </marquee>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        ) : (
          <div className="loading-spinnerH"></div>
        )}
      </div>
    </div>
  );
}

export default TopCollections;
