import React from 'react';
import './style.css';

function JoinDiscordPage() {
  return (
    <div className="container">
      <video className="video-background" autoPlay loop muted>
        <source src="GifBackground.mp4" type="video/mp4" />
      </video>
      <div className="card">
        <h1>Welcome to Wild Ones!</h1>
        <p>Join our community by clicking the button below and following the verification rules</p>
        <a href="https://discord.com/invite/wildones" target="_blank" rel="noopener noreferrer">
          Join Wild Ones on Discord
        </a>
      </div>
    </div>
  );
}


export default JoinDiscordPage;
