import React, { useState, useEffect } from 'react';
import LoadingPage from '../loading';
import { Newspaper } from 'lucide-react';
import './style.css';
import Message from '../favorites/Message.js';


function NewsPage() {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const fetchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/news/get');
                const data = await response.json();
                setNews(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        };

        fetchResults();
    }, []);

    function renderNews() {
        if (loading) {
            return <LoadingPage />;
        } else if (news && news.length) {
            let currentDate = '';
            return (
                <div className="results-list-news">
                    {news.map(item => {
                        const itemDate = formatDate(item.publishedTime);
                        let dateSeparator = null;
                        if (itemDate !== currentDate) {
                            currentDate = itemDate;
                            dateSeparator = <div style={{ border: "1px solid #fab00a", color: "white", borderRadius: '20px', paddingBottom: "3px", paddingLeft: "7px", paddingRight: "6px", fontSize: "13px" }}>{itemDate}</div>;
                        }
                        return (
                            <React.Fragment key={item.id}>
                                {dateSeparator}
                                <AnnouncementCard item={item} />
                            </React.Fragment>
                        );
                    })}
                </div>
            );
        } else {
            return <img src="/NoFavoriteResults.png" alt="No Results" className="no-results-img" style={{ marginTop: '-50px' }} />;
        }
    }


    return (
        <div className='news-containern'>
            <div className='title-store-n'>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div className='icon-store'>
                        <Newspaper color='#ffab00' size={isMobile ? 40 : 60} />
                    </div>
                    <div className='text-store'>
                        <h1>
                            <span style={{
                                borderBottom: '7px solid #ffab00',
                            }}>
                                NEWSLETTER
                            </span>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="tab-content" style={{width: '100%',}}>
                {renderNews(news)}
            </div>
        </div>
    );
};

export default NewsPage;


function AnnouncementCard({ item }) {
    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <div className={"announcement-card"}>
            <div style={{ userSelect: 'none' }} className="announcement-headern">
                <div style={{ color: 'red', fontSize: '14px', marginBottom: '5px' }}>{item.type}</div>
                <div className="project-name" style={{fontSize: '20px', marginTop: '0px' }}>{item.name}</div>
                <div className="project-time" style={{fontSize: '14px' }}>{formatTime(item.publishedTime)}</div>
            </div>
            <div className="announcement-content">
            <Message size={20} text={item.content} category={item.type} />
            </div>
        </div>
    );
}

function formatDate(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    const diffDays = Math.round(Math.abs((date - now) / oneDay));

    if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return 'Yesterday';
    } else if (diffDays === 2) {
        return '2 days ago';
    } else {
        return date.toLocaleDateString(); // This will return in the format MM/DD/YYYY. Adjust format as needed.
    }
}