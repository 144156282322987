import React, { useState, useEffect } from 'react';
import Sidebar, { SidebarItem } from '../Sidebar';
import { LayoutDashboard, Star, Search, Newspaper, BarChart4, User, Store } from 'lucide-react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { CSSTransition } from 'react-transition-group';
import './styles.css';

function SidebarWrapper() {
    const [cookies, setCookie] = useCookies(['activePage']);
    const [activeItem, setActiveItem] = useState(parseInt(cookies.activePage, 10) || 0);
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 768;
    const location = useLocation();

    useEffect(() => {
        if (/^\/profile(\/.*)?$/.test(location.pathname)) {
            setActiveItem(6);
            setCookie('activePage', 6, { path: '/' });
        } else if (/^\/settings(\/.*)?$/.test(location.pathname)) {
            setActiveItem(7);
            setCookie('activePage', 7, { path: '/' });
        } else {
            switch (location.pathname) {
                case '/dashboard':
                    setActiveItem(0);
                    setCookie('activePage', 0, { path: '/' });
                    break;
                case '/search':
                    setActiveItem(2);
                    setCookie('activePage', 2, { path: '/' });
                    break;
                case '/favorites':
                    setActiveItem(1);
                    setCookie('activePage', 1, { path: '/' });
                    break;
                case '/newsletter':
                    setActiveItem(3);
                    setCookie('activePage', 3, { path: '/' });
                    break;
                case '/leaderboard':
                    setActiveItem(4);
                    setCookie('activePage', 4, { path: '/' });
                    break;
                case '/wildstore':
                    setActiveItem(5);
                    setCookie('activePage', 5, { path: '/' });
                    break;
                default:
                    setActiveItem(0);
                    break;
            }
        }
    }, [location.pathname]);


    useEffect(() => {
        setActiveItem(parseInt(cookies.activePage, 10) || 0);
    }, [cookies]);

    const handleNavigation = (index, path) => {
        setActiveItem(index);
        setCookie('activePage', index, { path: '/' });
        navigate(path);
    };

    return (
        <div style={{ position: `${isMobile ? 'fixed' : 'static'}`, display: 'flex' }}>
            <Sidebar handleNavigation={handleNavigation} activeItem={activeItem}>
                <SidebarItem icon={<LayoutDashboard size={20} />} text="Dashboard" active={activeItem === 0} onClick={() => handleNavigation(0, '/dashboard')} />
                <SidebarItem icon={<Search size={20} />} text="Search" active={activeItem === 2} onClick={() => handleNavigation(2, '/search')} />
                <SidebarItem icon={<Star size={20} />} text="Favorites" active={activeItem === 1} onClick={() => handleNavigation(1, '/favorites')} />
                <SidebarItem icon={<Newspaper size={20} />} text="Newsletter" active={activeItem === 3} onClick={() => handleNavigation(3, '/newsletter')} />
                <SidebarItem icon={<BarChart4 size={20} />} text="Leaderboard" active={activeItem === 4} onClick={() => handleNavigation(4, '/leaderboard')} />
                <SidebarItem icon={<Store size={20} />} text="Wild Store" active={activeItem === 5} onClick={() => handleNavigation(5, '/wildstore')} />
                <SidebarItem icon={<User size={20} />} text="Profile" active={activeItem === 6} onClick={() => handleNavigation(6, '/profile')} />
            </Sidebar>
            <div style={{ flex: 1, overflowY: 'auto', backgroundImage: 'url(/background.png)' }}>
                <CSSTransition
                    key={location.pathname}
                    in={!!location.pathname}
                    timeout={300}
                    classNames="page"
                    unmountOnExit
                    appear
                >
                    <Outlet />
                </CSSTransition>
            </div>
        </div>
    );
}

export default SidebarWrapper;
