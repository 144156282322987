import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Store, ChevronLeft, ChevronRight, Search, RotateCw, XCircle } from 'lucide-react';
import { Dropdown } from 'flowbite-react';
import { Tooltip } from 'react-tooltip';
import Popup from 'react-popup';
import './style.css';
import LoadingPage from '../loading';
import { useAlert } from 'react-alert';

function WildStorePage() {
    const [cookies, setCookie] = useCookies(['activePage']);
    const [lightIntensity, setLightIntensity] = useState(2);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedButton, setSelectedButton] = useState('ALL');
    const [itemsPerPage, setItemsPerPage] = useState(8);
    // Mock data ₳
    const isMobile = window.innerWidth <= 768;
    const maxPagesToShow = 5;
    const [dropdownValue, setDropdownValue] = useState("Most Recent to Least");
    const [spin, setSpin] = useState(false);
    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const totalPages = Math.max(1, Math.ceil(filteredItems.length / itemsPerPage));
    const paginatedItems = filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const [searchTerm, setSearchTerm] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const alert = useAlert();
    const API_KEY = process.env.REACT_APP_OCNFT_API_KEY;

    setCookie('activePage', 5, { path: '/' });

    const fetchFloorPrice = async (policy, isNFT) => {
        if (!isNFT) {
            return;
        }
        try {
            // Check if data for this policy is already in localStorage
            const cachedData = localStorage.getItem(policy);
            if (cachedData) {
                const cachedFloorPrice = JSON.parse(cachedData);
                const currentTime = new Date().getTime();
                const age = currentTime - cachedFloorPrice.timestamp;
                const expiryTime = 30 * 60 * 1000;

                // If cached data is not expired, return it
                if (age < expiryTime) {
                    return cachedFloorPrice.floorPrice;
                }
            }

            // If no valid cached data, fetch new data
            const response = await fetch(`https://api.opencnft.io/2/collection/${policy}/floor_price`, {
                headers: {
                    'X-Api-Key': API_KEY,
                },
            });
            if (!response.ok) {
                return 0;
            }
            const data = await response.json();
            const floorPrice = data.floor_price / 1000000;

            // Store the new data in localStorage with a timestamp
            localStorage.setItem(policy, JSON.stringify({ floorPrice, timestamp: new Date().getTime() }));
            return floorPrice;
        } catch (error) {
            return 0;
        }
    };



    useEffect(() => {
        const updateItemsPerPage = () => {
            if (window.innerWidth <= 768) {
                setItemsPerPage(2); // e.g., 1 item per column * 2 rows
            } else if (window.innerWidth <= 1000) {
                setItemsPerPage(4); // e.g., 2 items per column * 2 rows
            } else if (window.innerWidth <= 1300) {
                setItemsPerPage(6); // e.g., 3 items per column * 2 rows
            } else {
                setItemsPerPage(8); // e.g., 5 items per column * 2 rows
            }
        };

        window.addEventListener('resize', updateItemsPerPage);
        updateItemsPerPage(); // Initial set

        return () => window.removeEventListener('resize', updateItemsPerPage); // Cleanup
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        let sortedItems = [...items];

        switch (dropdownValue) {
            case 'Most Recent to Least':
                sortedItems.sort((a, b) => new Date(b.uploadedTime) - new Date(a.uploadedTime));
                break;
            case 'Least Recent to Most':
                sortedItems.sort((a, b) => new Date(a.uploadedTime) - new Date(b.uploadedTime));
                break;
            case 'Price: Low to High':
                sortedItems.sort((a, b) => a.price - b.price);
                break;
            case 'Price: High to Low':
                sortedItems.sort((a, b) => b.price - a.price);
                break;
            default:
                break;
        }

        setItems(sortedItems);
    }, [dropdownValue]);

    useEffect(() => {
        let filtered = items;
        setCurrentPage(1);

        if (searchTerm) {
            filtered = filtered.filter(item =>
                item.project.toLowerCase().replace(" ", "").includes(searchTerm.toLowerCase().replace(" ", "")) ||
                item.name.toLowerCase().replace(" ", "").includes(searchTerm.toLowerCase().replace(" ", "")) ||
                item.quantity.toString().replace(" ", "").includes(searchTerm.replace(" ", "")) ||
                item.type.toLowerCase().replace(" ", "").includes(searchTerm.toLowerCase().replace(" ", ""))
            );
        }

        switch (selectedButton) {
            case 'NFT':
                filtered = filtered.filter(item => item.type === 'NFT');
                break;
            case 'WL':
                filtered = filtered.filter(item => item.type === 'Whitelist' || item.type === 'WL');
                break;
            case 'OTHER':
                filtered = filtered.filter(item => item.type !== 'NFT' && item.type !== 'Whitelist' && item.type !== 'WL');
                break;
            default:
                break;
        }

        setFilteredItems(filtered);
    }, [selectedButton, items, searchTerm]);


    useEffect(() => {
        fetch("https://wildones2023.oa.r.appspot.com/rest/wildstore/get")
            .then(response => {
                if (response.status === 204) {
                    setIsEmpty(true);
                    setSpin(false);
                    return [];
                }
                return response.json();
            })
            .then(async (data) => {
                const policyCache = {}; // Cache to store policy IDs
                const floorPriceCache = {}; // Cache to store floor prices

                // Map over items and fetch policy ID and floor price for each
                const itemsWithFloorPrice = await Promise.all(data.map(async (item) => {
                    // Check if policy ID is already in cache
                    if (!policyCache[item.project]) {
                        // If not, fetch policy ID and store it in cache
                        const policyResponse = await fetch(`https://wildones2023.oa.r.appspot.com/rest/projects/getProjectPolicyId`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ projectName: item.project }), // Send projectName as a property of a JSON object
                        });

                        const policyData = await policyResponse.json(); // Expecting a JSON response
                        const policyId = policyData.policyId; // Extract policyId from JSON response
                        policyCache[item.project] = policyId;
                    }


                    const policy = policyCache[item.project];

                    // Check if floor price is already in cache
                    if (!floorPriceCache[policy]) {
                        // If not, fetch floor price and store it in cache
                        const floorPrice = await fetchFloorPrice(policy, item.type === 'NFT');
                        floorPriceCache[policy] = floorPrice;
                    }

                    return { ...item, floorPrice: floorPriceCache[policy] };
                }));

                setItems(itemsWithFloorPrice);
            })
    }, []);


    useEffect(() => {
        const flickeringEffect = () => {
            setTimeout(() => setLightIntensity(1), 100); // Low intensity
            setTimeout(() => setLightIntensity(0), 200); // Off
            setTimeout(() => setLightIntensity(2), 300); // Low intensity
            setTimeout(() => setLightIntensity(0), 400); // Off
            setTimeout(() => setLightIntensity(1), 500); // Low intensity
            setTimeout(() => setLightIntensity(0), 600); // Off
            setTimeout(() => setLightIntensity(3), 700); // Full intensity
            setTimeout(() => setLightIntensity(0), 5500);
        };

        const interval = setInterval(() => {
            flickeringEffect();
        }, 6500);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    const getFilter = () => {
        switch (lightIntensity) {
            case 1:
                return "drop-shadow(0 0 1px white)";
            case 2:
                return "drop-shadow(0 0 2px white)";
            case 3:
                return "drop-shadow(0 0 3px white)";
            default:
                return "none";
        }
    };

    // const handleAddCredit = () => {
    //     Popup.create({
    //         title: 'Add Credit',
    //         content: 'You are about to add 500k $WLD to your account. Please note that this is for testing purposes only and WILL NOT reflect a real transaction. By confirming you accept these terms. Are you sure you want to proceed?',
    //         buttons: {
    //             left: ['cancel'],
    //             right: [{
    //                 text: 'Confirm',
    //                 className: 'success',
    //                 action: function () {
    //                     // Handle the purchase logic here
    //                     fetch(`https://wildones2023.oa.r.appspot.com/rest/user/addCredit`, {
    //                         method: 'POST',
    //                         headers: {
    //                             'Authorization': `Bearer ${cookies.username}-::${cookies.accessToken}`,
    //                         }
    //                     })
    //                         .then(response => {
    //                             if (!response.ok) {
    //                                 // If the response is not OK, throw an error to be caught in the catch block
    //                                 throw response;
    //                             }
    //                             return response.text();
    //                         })
    //                         .then(data => {
    //                             setCookie('purchase', 0 + "-" + Date.now(), { path: '/' });

    //                             // Use react-alert instead of Popup.alert
    //                             alert.success(`You have successfully added 500k $WLD to your account. You can now test the store.`);

    //                             fetch("https://wildones2023.oa.r.appspot.com/rest/wildstore/get")
    //                                 .then(response => {
    //                                     if (response.status === 204) {
    //                                         setIsEmpty(true);
    //                                         setSpin(false);
    //                                         return [];
    //                                     }
    //                                     return response.json();
    //                                 })
    //                                 .then(data => {
    //                                     setItems(data);
    //                                 });
    //                         })
    //                         .catch(error => {
    //                             error.text().then(errorMessage => {
    //                                 // Use react-alert for displaying errors as well
    //                                 alert.error(errorMessage);
    //                             });
    //                         });
    //                     Popup.close();
    //                 }
    //             }]
    //         }
    //     });
    // };

    const handlePurchase = (itemPrice, itemId, itemName) => {
        Popup.create({
            title: 'Purchase Confirmation',
            content: `Are you sure you want to purchase "${itemName}" for ${itemPrice} $WLD?`,
            buttons: {
                left: ['cancel'],
                right: [{
                    text: 'Confirm',
                    className: 'success',
                    action: function () {
                        // Handle the purchase logic here
                        fetch(`https://wildones2023.oa.r.appspot.com/rest/wildstore/purchaseItem/${itemId}`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${cookies.username}-::${cookies.accessToken}`,
                            }
                        })
                            .then(response => {
                                if (!response.ok) {
                                    // If the response is not OK, throw an error to be caught in the catch block
                                    throw response;
                                }
                                return response.text();
                            })
                            .then(data => {
                                setCookie('purchase', itemId + "-" + Date.now(), { path: '/' });

                                // Use react-alert instead of Popup.alert
                                alert.success(`You have successfully purchased "${itemName}"!`);

                                fetch("https://wildones2023.oa.r.appspot.com/rest/wildstore/get")
                                    .then(response => {
                                        if (response.status === 204) {
                                            setIsEmpty(true);
                                            setSpin(false);
                                            return [];
                                        }
                                        return response.json();
                                    })
                                    .then(data => {
                                        setItems(data);
                                    });
                            })
                            .catch(error => {
                                error.text().then(errorMessage => {
                                    // Use react-alert for displaying errors as well
                                    alert.error(errorMessage);
                                });
                            });
                        Popup.close();
                    }
                }]
            }
        });
    };


    if (items.length === 0 && !isEmpty)
        return <LoadingPage />;

    return (
        <>
            <div className={`main-content ${isImageModalOpen ? 'blurred-background' : ''}`}>
                {/* <div style={{ position: 'fixed', top: '25px', left: '50%', color: 'white' }}> <button onClick={() => handleAddCredit()}>Add Credit</button> </div> */}

                <div className='title-store w'>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        filter: getFilter(),
                        transition: "filter 0.1s ease"
                    }}>
                        <div className='icon-store'>
                            <Store color='#ffab00' size={isMobile ? 40 : 60} />
                        </div>
                        <div className='text-store'>
                            <h1>
                                <span style={{
                                    borderBottom: '7px solid #ffab00',
                                }}>
                                    WILD STORE
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className='search-inner-container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                    <div className="mobile-search-container">
                        <div style={{ display: 'flex', background: '#444', borderRadius: '5px', width: '100%', minWidth: '250px', marginLeft: '12.5%' }}>
                            <Search color="white" size={20} style={{ marginLeft: '10px', marginTop: '10px' }} />
                            <input
                                type="text"
                                placeholder="Search for item name, project, type or quantity ..."
                                style={{
                                    padding: '10px',
                                    background: 'transparent',
                                    color: 'white',
                                    border: 'none',
                                    width: '100%',
                                    outline: 'none'
                                }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className='search-refresh'>
                            <RotateCw
                                color="white"
                                size={20}
                                className={spin ? 'spin' : ''}
                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                onClick={() => {
                                    if (spin) return; // Prevent multiple refreshes
                                    setSpin(true);
                                    setTimeout(() => setSpin(false), 3000);
                                    // Fetch items from the backend
                                    fetch("https://wildones2023.oa.r.appspot.com/rest/wildstore/get")
                                        .then(response => {
                                            if (response.status === 204) {
                                                setIsEmpty(true);
                                                throw new Error("No items in store.");
                                            }
                                            return response.json();
                                        })
                                        .then(data => async () => {
                                            const updatedItems = await Promise.all(data.map(async item => {
                                                const floorPrice = await fetchFloorPrice(item.policy, item.type === 'NFT');
                                                return { ...item, floorPrice }; // Return the item with the updated floor price
                                            }));
                                            setItems(updatedItems);
                                        })
                                        .catch(error => {
                                            console.error("Error fetching items:", error);
                                            setSpin(false); // Reset the spin state in case of error
                                        });
                                }}
                                data-tooltip-id="refresh-tooltip"
                                data-tooltip-content="Refresh the store"
                            />
                        </div>
                    </div>

                    <Tooltip id="refresh-tooltip" border={false} style={{ userSelect: 'none' }} />

                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginRight: '10%', userSelect: 'none' }}>
                        <div className="filter-buttons" style={{ display: 'flex', width: '300px' }}>
                            <button
                                className={`ALL ${selectedButton === 'ALL' ? 'selected' : ''}`}
                                onClick={() => setSelectedButton('ALL')}
                            >
                                ALL
                            </button>
                            <button
                                className={`NFT ${selectedButton === 'NFT' ? 'selected' : ''}`}
                                onClick={() => setSelectedButton('NFT')}
                            >
                                NFT
                            </button>
                            <button
                                className={`WL ${selectedButton === 'WL' ? 'selected' : ''}`}
                                onClick={() => setSelectedButton('WL')}
                            >
                                WL
                            </button>
                            <button
                                className={`OTHER ${selectedButton === 'OTHER' ? 'selected' : ''}`}
                                onClick={() => setSelectedButton('OTHER')}
                            >
                                OTHER
                            </button>
                        </div>

                        <div className="filter-dropdown">
                            <Dropdown
                                label={<span className="text-[#777] flex items-center h-3 p-1">{dropdownValue}</span>}
                                className="bg-[#111] p-2 z-50 text-center text-[#777]"
                            >
                                <Dropdown.Item
                                    className='bg-[#111] text-[#777] hover:bg-gray-700 p-2'
                                    onClick={() => setDropdownValue('Most Recent to Least')}
                                >
                                    Most Recent to Least
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className='bg-[#111] text-[#777] hover:bg-gray-700 p-2'
                                    onClick={() => setDropdownValue('Least Recent to Most')}
                                >
                                    Least Recent to Most
                                </Dropdown.Item>
                                <Dropdown.Divider className='border-gray-600' />
                                <Dropdown.Item
                                    className='bg-[#111] text-[#777] hover:bg-gray-700 p-2'
                                    onClick={() => setDropdownValue('Price: Low to High')}
                                >
                                    Price: Low to High
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className='bg-[#111] text-[#777] hover:bg-gray-700 p-2'
                                    onClick={() => setDropdownValue('Price: High to Low')}
                                >
                                    Price: High to Low
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {isEmpty ? <img style={{ textAlign: 'center', margin: '50px auto' }} src="NoResults.png" alt="No Results" /> : (
                    <div className="grid-container" style={{ gridTemplateColumns: `repeat(${itemsPerPage / 2}, 1fr)` }}>
                        {paginatedItems.map((item) => (
                            <div className="item-card-wrapper" >
                                <div className="item-card" key={item.id}>
                                    <div className="item-type">
                                        {item.type === "NFT" ? <span style={{ color: "#181818" }}>NFT</span> : item.type === "Whitelist" || item.type === "WL" ? <span style={{ color: "green" }}>Whitelist</span> : <span style={{ color: "purple" }}>{item.type}</span>}
                                    </div>
                                    <img
                                        src={item.imageUrl}
                                        alt={item.name}
                                        className="item-image"
                                        onClick={() => {
                                            setSelectedImage(item.imageUrl);
                                            setImageModalOpen(true);
                                        }}
                                    />
                                    <div className="item-details">
                                        <div className="item-info">
                                            <span className="item-name">{item.name}</span>
                                            {item.type === "NFT" ? <span className="floor-price">Floor Price: {item.floorPrice} ₳ </span> : item.type === "Whitelist" || item.type === "WL" ? <span className="quantity-left">WLs left: {item.quantity} </span> : <span className="quantity-left">Items left: {item.quantity} </span>}
                                        </div>
                                        <span className="item-price" data-text={`${formatVolume(item.price)}`} onClick={() => handlePurchase(item.price, item.id, item.name)}></span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {filteredItems.length > 0 && (
                    <div className="pagination">
                        <div className="chevron-container">
                            <ChevronLeft color='wheat'
                                className={currentPage === 1 ? 'disabled' : ''}
                                onClick={() => currentPage > 1 && setCurrentPage(prev => prev - 1)}
                            />
                        </div>
                        {Array.from({ length: totalPages }).map((_, index) => {
                            const pageNumber = index + 1;
                            if (pageNumber === currentPage ||
                                pageNumber > currentPage - maxPagesToShow / 2 &&
                                pageNumber < currentPage + maxPagesToShow / 2) {
                                return (
                                    <span
                                        key={pageNumber}
                                        className={`page-number ${pageNumber === currentPage ? 'active' : ''}`}
                                        onClick={() => setCurrentPage(pageNumber)}
                                    >
                                        {pageNumber}
                                    </span>
                                );
                            }
                            return null;
                        })}
                        <div className="chevron-container">
                            <ChevronRight color='wheat'
                                className={currentPage === totalPages ? 'disabled' : ''}
                                onClick={() => currentPage < totalPages && setCurrentPage(prev => prev + 1)}
                            />
                        </div>
                    </div>
                )}

            </div>
            {isImageModalOpen && (
                <div className="image-modal-overlay">
                    <div className="image-modal-content">
                        <img src={selectedImage} alt="Expanded" className="expanded-image" />
                        <button className="close-modal-btn" onClick={() => setImageModalOpen(false)}><XCircle size={35} /></button>
                    </div>
                </div>
            )}
            <Popup />
        </>
    );
}

export default WildStorePage;

const formatVolume = (volume) => {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
        formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
        formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', '') + ' $WLD'; // Remove trailing .0 if present
};