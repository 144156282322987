import React, { useState, useEffect } from 'react';
import './style.css';
import { ChevronLeft, ChevronRight, Search, BarChart4 } from 'lucide-react';
import LoadingPage from '../loading';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

function LeaderboardPage() {
    const [cookies, setCookie] = useCookies([]);
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState('');
    const itemsPerPage = 5;
    const userRank = players.findIndex(player => player.username === cookies.username) + 1;
    const userPage = Math.ceil(userRank / itemsPerPage);
    const isMobile = window.innerWidth <= 768;
    const [lightIntensity, setLightIntensity] = useState(2);

    useEffect(() => {
        setLoading(true);
        fetch('https://wildones2023.oa.r.appspot.com/rest/user/leaderboard')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok', response.statusText);
                }
                return response.json();
            })
            .then(data => {
                // Assign rank to each player
                const rankedData = data.map((player, index) => ({
                    ...player,
                    rank: index + 1
                }));
                setPlayers(rankedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
                setLoading(false);
            });
    }, []);

    const filteredPlayers = players.filter(player => {
        const level = getUserLevel(player.xp);
        const roleMatches = player.oneOfOneRoles ? player.oneOfOneRoles.some(role => role.toLowerCase().includes(query.toLowerCase())) : false;
        return (
            player.username.toLowerCase().includes(query.toLowerCase()) ||
            player.displayName.toLowerCase().includes(query.toLowerCase()) ||
            player.nftrole.toLowerCase().includes(query.toLowerCase()) ||
            player.gcrole.toLowerCase().includes(query.toLowerCase()) ||
            (`lv. ${level}`).toLowerCase().includes(query.toLowerCase()) ||
            roleMatches
        );
    });

    useEffect(() => {
        setCurrentPage(1);
    }, [query]);

    const totalPages = Math.ceil(filteredPlayers.length / itemsPerPage);

    const renderUsers = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredPlayers.slice(startIndex, endIndex).map(user => (
            <Link to={`/profile/${user.username}`} key={user.username}>
                <div key={user.rank} className={`user-cardl ${user.username === cookies.username ? 'highlight' : ''}`}>
                    <div className="user-rowl">
                        <div className="user-infol">
                            <div className="positionl">{user.rank}.</div>
                            <div className="avatarl"><img src={user.avatarUrl} alt={user.displayName} /></div>
                            <div className="detailsl">
                                <p>{user.displayName}</p>
                                <small>@{user.username}</small>
                            </div>
                        </div>
                        <div className="levell">{`Lv. ${getUserLevel(user.xp)}`}</div>
                        <div className="xpl">{formatXP(user.xp)}</div>
                        <div className="badges-containerl">
                            {user.nftrole !== "Not Verified" && (
                                <div className="badge" style={{
                                    background: getBadgeColor(user.nftrole),
                                    color: isColorBright(dominantColorFromGradient(getBadgeColor(user.nftrole))) ? '#000' : '#FFF',
                                    marginBottom: '10px'
                                }}>
                                    {user.nftrole}
                                </div>
                            )}
                            {user.gcrole !== "No GCRole" && (
                                <div className="badge" style={{
                                    background: getBadgeColor(user.gcrole),
                                    color: isColorBright(dominantColorFromGradient(getBadgeColor(user.gcrole))) ? '#000' : '#FFF',
                                }}>
                                    {user.gcrole}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        ));
    };

    return (
        <div>
            {loading ? <LoadingPage /> : <>
                <div className='title-store l'>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <div className='icon-store'>
                            <BarChart4 color='#ffab00' size={isMobile ? 40 : 60} />
                        </div>
                        <div className='text-store'>
                            <h1>
                                <span style={{
                                    borderBottom: '7px solid #ffab00',
                                }}>
                                    LEADERBOARD
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="search-containerl">
                    <Search className="search-iconl" color='white' />
                    <input
                        type="text"
                        placeholder="Search for name, username, badge and level!"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="search-barl"
                    />
                    <button
                        onClick={() => setCurrentPage(userPage)}
                        style={{
                            color: 'white',
                            padding: '3px 5px',
                            marginLeft: '10px',
                            borderRadius: '20px',
                            border: '1px solid #fab00a',
                            cursor: 'pointer',
                            fontSize: '14px',
                            transition: '0.3s',
                            outline: 'none',
                            whiteSpace: 'nowrap',
                        }}
                        onMouseOver={(e) => e.currentTarget.style.scale = '1.05'}
                        onMouseOut={(e) => e.currentTarget.style.scale = '1'}
                    >
                        Find me!
                    </button>

                </div>
                <div className="leaderboard-containerl">
                    <div className="user-rowl column-titlesl">
                        <div className="user-title">User</div>
                        <div className="level-title">Level</div>
                        <div className="xp-title">XP</div>
                        <div className="rank-title">Badges</div>
                    </div>

                    {renderUsers()}

                    {filteredPlayers.length > 0 && (
                        <div className="pagination" style={{ marginTop: '10px' }}>
                            <div className="chevron-container">
                                <ChevronLeft color='wheat'
                                    className={currentPage === 1 ? 'disabled' : ''}
                                    onClick={() => currentPage > 1 && setCurrentPage(prev => prev - 1)}
                                />
                            </div>
                            {Array.from({ length: totalPages }).map((_, index) => (
                                <span
                                    key={index + 1}
                                    className={`page-number ${index + 1 === currentPage ? 'active' : ''}`}
                                    onClick={() => setCurrentPage(index + 1)}
                                >
                                    {index + 1}
                                </span>
                            ))}
                            <div className="chevron-container">
                                <ChevronRight color='wheat'
                                    className={currentPage === totalPages ? 'disabled' : ''}
                                    onClick={() => currentPage < totalPages && setCurrentPage(prev => prev + 1)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </>}
        </div>
    );
}

export default LeaderboardPage;

function dominantColorFromGradient(gradient) {
    const matches = gradient.match(/#[a-fA-F0-9]{6}/g);  // This regular expression finds HEX colors
    return matches ? matches[0] : null;
}

function isColorBright(hex) {
    if (!hex || hex === '#') {
        return false;
    }

    let r, g, b;

    if (hex.length === 7) {
        r = parseInt(hex.substring(1, 3), 16);
        g = parseInt(hex.substring(3, 5), 16);
        b = parseInt(hex.substring(5, 7), 16);
    } else if (hex.length === 4) {
        r = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
        g = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
        b = parseInt(hex.substring(3, 4) + hex.substring(3, 4), 16);
    } else {
        return false;
    }

    // Luminance formula: 0.299 * R + 0.587 * G + 0.114 * B
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.7;
}

function formatXP(volume) {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
        formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
        formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', ''); // Remove trailing .0 if present
};

const xpThresholds = [100, 105, 110, 115, 120, 126, 132, 138, 144, 151, 158, 165, 173, 181, 190, 199, 208, 218, 228, 239, 250, 262, 275, 288, 302, 317, 332, 348, 365, 383, 402, 422, 443, 465, 488, 512, 537, 563, 591, 620, 651, 683, 717, 752, 789, 828, 869, 912, 957, 1004, 1054, 1106, 1161, 1219, 1280, 1344, 1411, 1481, 1555, 1632, 1713, 1798, 1888, 1982, 2081, 2185, 2294, 2408, 2528, 2654, 2786, 2925, 3071, 3224, 3385, 3554, 3731, 3917, 4113, 4318, 4534, 4761, 4999, 5249, 5511, 5787, 6076, 6380, 6699, 7034, 7386, 7755, 8143, 8550, 8978, 9427, 9899, 10394, 10914];

function getUserLevel(xp) {
    let totalXP = 0;
    let previousXPThreshold = 0;

    for (let i = 0; i < xpThresholds.length; i++) {
        totalXP += xpThresholds[i];
        previousXPThreshold = totalXP - xpThresholds[i];
        if (xp < totalXP) {
            return i + 1;
        }
    }
    return xpThresholds.length + 1;
}

function getBadgeColor(role) {
    switch (role) {
        case "Wild Dragon":
            return "linear-gradient(45deg, red, pink)";
        case "Basilik":
            return "linear-gradient(45deg, gray, lightgray)";
        case "Komodo King":
            return "linear-gradient(45deg, lightgreen, green)";
        case "Chameleon":
            return "linear-gradient(45deg, green, darkgreen)";
        case "Chief Salamander":
            return "linear-gradient(45deg, orange, darkorange)";
        case "Lizard Soldier":
            return "linear-gradient(45deg, #BF40BF, #702963)";
        case "Outlaw Gecko":
            return "linear-gradient(45deg, #556B2F, gray)";
        case "Wild Monster":
            return "linear-gradient(45deg, pink, deeppink)";
        case "Wild One":
            return "linear-gradient(45deg, blue, deepskyblue)";
        case "GC Holder":
            return "linear-gradient(45deg, #ffba00, gold)";
        case "GC Master":
            return "linear-gradient(45deg, gold, goldenrod)";
        case "GC Whale":
            return "linear-gradient(45deg, #b9f2ff, #00ced1)";
        case "GC Dragon":
            return "linear-gradient(45deg, #858205, #525003)";
        case "Joker":
            return "linear-gradient(45deg, #5049ff, #3833b2)";
        case "Ancient":
            return "linear-gradient(45deg, #fdff5c, #b1b240)";
        case "Sketchpa":
            return "linear-gradient(45deg, #411c1c, #8d3c3c)";
        case "Baseball Player":
            return "linear-gradient(45deg, #99ec2f, #679f1f)";
        case "Basquiat":
            return "linear-gradient(45deg, #776c6c, #2a2626)";
        case "Mondrian":
            return "linear-gradient(45deg, #ff9c9c, #b26d6d)";
        case "Her Lizard":
            return "linear-gradient(45deg, #c300ae, #43003c)";
        default:
            return "transparent";
    }
}

function getBadgeMainColor(role) {
    switch (role) {
        case "Wild Dragon":
            return "red";
        case "Basilik":
            return "gray";
        case "Komodo King":
            return "lightgreen";
        case "Chameleon":
            return "green";
        case "Chief Salamander":
            return "orange";
        case "Lizard Soldier":
            return "#BF40BF";
        case "Outlaw Gecko":
            return "#556B2F";
        case "Wild Monster":
            return "pink";
        case "Wild One":
            return "blue";
        case "GC Holder":
            return "#ffba00";
        case "GC Master":
            return "gold";
        case "GC Whale":
            return "#b9f2ff";
        case "GC Dragon":
            return "#858205";
        default:
            return "transparent";
    }
}