import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Leaderboard from '../../components/Leaderboard';
import Top5Collections from '../../components/Top5Collections';
import LatestPurchases from '../../components/LatestPurchases';
import News from '../../components/News';
import './style.css';
import LoadingPage from '../loading';
import { Trophy, Newspaper, TrendingUp, Tags } from 'lucide-react';

function DashboardPage() {
    const [cookies, setCookie] = useCookies(['activePage']);
    const [collectionsData, setCollectionsData] = useState([]);
    const [recordsData, setRecordsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expanded, setExpanded] = useState(cookies.sidebarExpanded);
    const API_KEY = process.env.REACT_APP_OCNFT_API_KEY;
    setCookie('activePage', 0, { path: '/' });

    useEffect(() => {

        const currentTime = new Date().getTime();
        const expiryTime = 30 * 60 * 1000; // 30 minutes

        const cachedData = localStorage.getItem('collectionsData');
        if (cachedData) {
            const cachedCollections = JSON.parse(cachedData);
            const age = currentTime - cachedCollections.timestamp;

            if (age < expiryTime) {
                setCollectionsData(cachedCollections.data);
                setIsLoading(false);
                return;
            }
        }

        // If no valid cached data, fetch new data
        fetch(`https://api.opencnft.io/2/market/rank/collection?time_range=30d`, {
            headers: {
                'X-Api-Key': API_KEY,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const newRanking = data.ranking;
                setCollectionsData(newRanking);
                localStorage.setItem('collectionsData', JSON.stringify({ data: newRanking, timestamp: new Date().getTime() }));
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching collections:', error);
                setIsLoading(false); // Error occurred but stop loading
            });
    }, []);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/wildstore/getLast5PurchasedItems`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user rank');
                }
                const data = await response.json();
                setRecordsData(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setExpanded(cookies.sidebarExpanded);
    }, [cookies.sidebarExpanded]);

    if (isLoading) {
        return <LoadingPage />; // Display the Loading component if data is being fetched
    }

    return (
        <div className='dashboard-container'>
            <div className='news-wrapper pl-5 pr-5'>
                <div className="news-header" style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                    <Newspaper color='#ffba00' />
                    <h2 style={{ fontSize: '20px', color: 'white', marginLeft: '10px' }}>Wild News</h2>
                </div>
                <News />
            </div>
            <div className="leaderboard-wrapper">
                <div className="leaderboard-header" style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                    <Trophy color='#ffba00' />
                    <h2 style={{ fontSize: '20px', color: 'white', marginLeft: '10px' }}>Top 10 Wild Ones</h2>
                </div>
                <Leaderboard />
            </div>
            <div className='trending-wrapper pl-5 pr-5'>
                <div className="trending-header" style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                    <TrendingUp color='#ffba00' />
                    <h2 style={{ fontSize: '20px', color: 'white', marginLeft: '10px' }}>Trending Collections</h2>
                </div>
                <Top5Collections collectionsData={collectionsData} />
            </div>
            <div className='last-purchases-wrapper pl-5 pb-5 pr-5'>
                <div className="last-purchases-header" style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                    <Tags color='#ffba00' />
                <h2 style={{ fontSize: '20px', color: 'white', marginLeft: '10px' }}>Latest Wild Sales</h2>
                </div>
                <LatestPurchases recordsData={recordsData} />
            </div>
        </div>
    );
}
export default DashboardPage;
