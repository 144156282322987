import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Search, ArrowDownAZ, ArrowUpAZ } from 'lucide-react';
import LoadingPage from '../loading';
import './style.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Message from './Message';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function FavoritesPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['activePage']);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [filteredFavorites, setfilteredFavorites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const queryParams = new URLSearchParams(location.search);
    const [announcementTab, setAnnouncementTab] = useState('announcements');
    const [filteredAnnouncements, setFilteredAnnouncements] = useState({ announcements: [], games: [], giveaways: [] });
    const [value, setValue] = useState(0); // or another default value
    const [selectedProject, setSelectedProject] = useState([]);
    const animatedComponents = makeAnimated();

    const tabsContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',

        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        overflow: 'hidden', // This makes sure the tab corners are also rounded
        width: '80%',
        marginLeft: '10%',
        marginTop: '20px',
    };
    const tabStyle = {
        flex: 1,
        padding: '10px 20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: '#fff',
        borderRight: '1px solid grey',
    };

    const lastTabStyle = {
        flex: 1,
        padding: '10px 20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: '#fff',
    };

    const selectedTabStyle = {
        ...tabStyle,
        backgroundColor: '#333',
    };

    const lastSelectedTabStyle = {
        ...lastTabStyle,
        backgroundColor: '#333',
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#181818',
            color: '#FCB800',
            boxShadow: state.isFocused ? '0 0 0 1px #FCB800' : 0,
            borderColor: state.isFocused ? '#FCB800' : provided.borderColor,
            '&:hover': {
                borderColor: state.isFocused ? '#FCB800' : provided.borderColor,
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'gray' : '#181818',
            color: state.isSelected ? '#FCB800' : 'white',
            '&:hover': {
                backgroundColor: '#FCB800',
                color: 'black',
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#FCB800' : '#333',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'black',
        }),
        input: (provided) => ({
            ...provided,
            color: '#333',
        }),
    };

    useEffect(() => {
        const tab = queryParams.get('tab');
        if (tab === 'notifications-announcements') {
            setAnnouncementTab('announcements');
        } else if (tab === 'notifications-games') {
            setAnnouncementTab('games');
        } else if (tab === 'notifications-giveaways') {
            setAnnouncementTab('giveaways');
        }
    }, [location.search]);


    const handleOuterChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            navigate('/favorites?tab=notifications-announcements');
        } else if (newValue === 1) {
            navigate('/favorites?tab=favorites');
        }
    };

    const handleInnerChange = (newValue) => {
        setAnnouncementTab(newValue);
        if (newValue === 'announcements') {
            navigate('/favorites?tab=notifications-announcements');
        } else if (newValue === 'games') {
            navigate('/favorites?tab=notifications-games');
        } else if (newValue === 'giveaways') {
            navigate('/favorites?tab=notifications-giveaways');
        }
    };

    const filterRes = async (dataToFilter) => {
        if (!dataToFilter) dataToFilter = results;
        const filterResults = dataToFilter.filter(item => {
            if (query.length === 0) return true;
            return levenshtein(item.name.toLowerCase(), query.toLowerCase()) <= 3 ||
                item.name.toLowerCase().includes(query.toLowerCase()) ||
                query.toLowerCase().includes(item.name.toLowerCase());
        });

        const sortedResults = filterResults.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });

        setfilteredFavorites(sortedResults);
    };


    useEffect(() => {
        setCookie('activePage', 1, { path: '/' });

        const fetchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/user/listFavorites', {
                    headers: {
                        'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`  // Assuming you store the user's token in the cookies
                    }
                });
                const data = await response.json();
                setResults(data);
                filterRes(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        };

        const fetchAnnouncements = async () => {
            try {
                const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/user/listFavoriteAnnouncements', {
                    headers: {
                        'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`
                    }
                });
                const data = await response.json();
                const announcements = data
                    .filter(item => item.category === 'announcements' && (selectedProject.length === 0 || selectedProject.includes(item.projectName)));
                const games = data
                    .filter(item => item.category === 'games' && (selectedProject.length === 0 || selectedProject.includes(item.projectName)));
                const giveaways = data
                    .filter(item => item.category === 'giveaways' && (selectedProject.length === 0 || selectedProject.includes(item.projectName)));

                setFilteredAnnouncements({ announcements, games, giveaways });
            } catch (error) {
                console.error("Error fetching announcements:", error);
            }
        };

        fetchAnnouncements();
        fetchResults();

    }, [selectedProject]);

    useEffect(() => {
        filterRes();
    }, [query, sortOrder]);

    function renderAnnouncements(announcements) {
        if (loading) {
            return <LoadingPage />;
        } else if (announcements && announcements.length) {
            let currentDate = '';
            return (
                <div className="results-list-announcements">
                    {announcements.map((item, index) => {
                        const itemDate = formatDate(item.timestamp);
                        let dateSeparator = null;
                        if (itemDate !== currentDate) {
                            currentDate = itemDate;
                            dateSeparator = <div style={{ border: "1px solid #fab00a", color: "white", borderRadius: '20px', paddingBottom: "3px", paddingLeft: "7px", paddingRight: "6px", fontSize: "13px" }}>{itemDate}</div>;
                        }
                        return (
                            <React.Fragment key={item.id + index}>
                                {dateSeparator}
                                <AnnouncementCard item={item} />
                            </React.Fragment>
                        );
                    })}
                </div>
            );
        } else {
            return <img src="/NoFavoriteResults.png" alt="No Results" className="no-results-img" style={{ marginTop: '-50px' }} />;
        }
    }

    return (
        <Box style={{ marginLeft: "20px", marginTop: "10px" }} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                value={value}
                onChange={handleOuterChange} // Updated to handleOuterChange
                aria-label="basic tabs example"
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#fab00a"
                    }
                }}
            >
                <Tab
                    label="Notifications"
                    sx={{
                        color: value === 0 ? "#fab00a" : "#ccc",
                        "&.Mui-selected": {
                            color: "#fab00a"
                        },
                        "&:hover": {
                            color: "#fab00a"
                        }
                    }}
                />
                <Tab
                    label="Favorites"
                    sx={{
                        color: value === 1 ? "#fab00a" : "#ccc",
                        "&.Mui-selected": {
                            color: "#fab00a"
                        },
                        "&:hover": {
                            color: "#fab00a"
                        }
                    }}
                />
            </Tabs>

            <TabPanel value={value} index={0}>
                <div style={tabsContainerStyle}>
                    <div
                        style={announcementTab === 'announcements' ? selectedTabStyle : tabStyle}
                        onClick={() => handleInnerChange('announcements')}
                    >
                        Announcements
                    </div>
                    <div
                        style={announcementTab === 'games' ? selectedTabStyle : tabStyle}
                        onClick={() => handleInnerChange('games')}
                    >
                        Games
                    </div>
                    <div
                        style={announcementTab === 'giveaways' ? lastSelectedTabStyle : lastTabStyle}
                        onClick={() => handleInnerChange('giveaways')}
                    >
                        Giveaways
                    </div>
                </div>
                <div className="tab-content" style={{width: '100%', marginTop: '20px'}}>
                    <div className='dropdown-wrapper' style={{ width: '65%', marginLeft: '17.5%', marginBottom: '20px' }}>
                        <Select
                            width='90%'
                            noOptionsMessage={() => "Add favorite projects in Search Page!"}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            placeholder='Filter by project'
                            onChange={(e) => setSelectedProject(e.map(option => option.value))}
                            isMulti
                            options={results.map(item => ({ value: item.name, label: item.name }))}
                            styles={customStyles}
                        />
                    </div>
                    {announcementTab === 'announcements' && renderAnnouncements(filteredAnnouncements.announcements)}
                    {announcementTab === 'games' && renderAnnouncements(filteredAnnouncements.games)}
                    {announcementTab === 'giveaways' && renderAnnouncements(filteredAnnouncements.giveaways)}
                </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <div className='favorites-page'>
                    {!loading && (
                        <div className="search-container">
                            <Search className="search-iconf" color='white' />
                            <input
                                type="text"
                                placeholder="Search..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                className="search-barf"
                            />
                            <Button className="order-buttonf" style={{ marginLeft: "5px" }} onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}>
                                {sortOrder === 'asc' ? <ArrowDownAZ color='white' /> : <ArrowUpAZ color='white' />}
                            </Button>
                        </div>
                    )}
                    {loading ? (
                        <LoadingPage />
                    ) : filteredFavorites.length ? (
                        <div className="results-list">
                            {filteredFavorites.map(item => <ItemCard key={item.id} item={item} />)}
                        </div>
                    ) : (
                        <img src="/NoResults.png" alt="No Results" className="no-results-img" style={{ marginTop: '-20px' }} />
                    )}
                </div>
            </TabPanel>
        </Box>
    );
}

export default FavoritesPage;

function ItemCard({ item }) {
    return (
        <Link
            to={{
                pathname: `/project/${item.name}`,
                state: { item: item }
            }}
            className="card-link"
        >
            <Card style={{ width: '18rem' }}>
                <Card.Title style={{ marginBottom: "15px", fontSize: "20px", color: 'white' }}>{item.name}</Card.Title>
                <Card.Img
                    variant="top"
                    src={item.imageUrl}
                    className="styled-image"
                />
                <Card.Body>
                    <Card.Text className="pt-4 pl-4 pr-4 truncate-text">
                        {item.description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Link>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box key={index}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function formatDate(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    const diffDays = Math.round(Math.abs((date - now) / oneDay));

    if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return 'Yesterday';
    } else if (diffDays === 2) {
        return '2 days ago';
    } else {
        return date.toLocaleDateString(); // This will return in the format MM/DD/YYYY. Adjust format as needed.
    }
}


function AnnouncementCard({ item }) {
    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <div className={"announcement-card"}>
          <div style={{ userSelect: 'none' }} className="announcement-header">
            <img src={item.projectImageUrl} alt={item.projectName} className="project-image" />
            <span className="project-name">{item.projectName}</span>
            <Tooltip id="OpenDiscord" border={false} style={{ userSelect: 'none' }} />
            <a href={`https://discord.com/channels/${item.serverId}/${item.channel_id}/${item.id}`} target="_blank" rel="noopener noreferrer">
              <img src="/logo/disc-logo.png" alt="Open in Discord" className="external-icon" data-tooltip-content="Open in Discord" data-tooltip-id="OpenDiscord" style={{ marginLeft: '10px', marginTop: '-3px', cursor: 'pointer', width: '25px', height: '25px' }} />
            </a>
            <span className="project-time">{formatTime(item.timestamp)}</span>
          </div>
          <div className="announcement-content">
            <Message size={20} text={item.content} category={item.category} />
          </div>
        </div>
      );      
}


function levenshtein(a, b) {
    const matrix = [];
    let i, j;

    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
            }
        }
    }

    return matrix[b.length][a.length];
}