import React, { useState, useRef } from 'react';
import './style.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function News() {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current index
  const [isTransitioning, setIsTransitioning] = useState(false);
  const hasNews = news && news.length > 0;
  const collection = news[currentIndex];
  const [hasFetched, setHasFetched] = useState(false);
  const navigate = useNavigate();

  const nextButtonRef = useRef(null); // Reference for the 'Next' button

  function changeNewsAutomatically() {
    const collectionDetails = document.querySelector('.collection-detailsn');
    const bigButton = document.querySelector('.newsb .big-button');

    // Simulate button press
    bigButton.classList.add('simulate-press');

    // Add the transitioning effect for the news details
    collectionDetails.classList.add('transitioning');

    // Remove the button press and transitioning effects after the transition duration
    setTimeout(() => {
      bigButton.classList.remove('simulate-press');
      collectionDetails.classList.remove('transitioning');
    }, 300); // 300 milliseconds or 0.3 seconds
  }

  useEffect(() => {
    if (news.length > 1) {
      const intervalId = setInterval(() => {
        // Simulate the button click
        if (nextButtonRef.current) {
          nextButtonRef.current.click();
          changeNewsAutomatically();
        }
      }, 25000);

      // Clear the interval when it reaches the first news or component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [currentIndex, news]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/news/get');
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setHasFetched(true);
      }
    };

    fetchResults();
  }, []);

  if (!hasFetched) return <div className={`newsb news-inner-wrapper`}></div>;

  if (!hasNews && hasFetched) return (
    <div className={`newsb news-inner-wrapper`}>

      <div style={{ overflow: 'hidden', width: '80%', marginLeft: '25px', marginTop: '10px' }}>
        <div className="collection-detailsn">
          <h3 className="collection-titlen">No recent news available!</h3>
          <span></span>
        </div>
      </div>
    </div>
  );

  if (news.length === 1 && hasFetched) return (
    <div className={`newsb news-inner-wrapper`}>

      <div style={{ overflow: 'hidden', width: '80%', marginLeft: '25px', marginTop: '10px' }} onClick={() => navigate('/newsletter')}>
        <div className="collection-detailsn">
          <h3 className="collection-titlen">{news[0].name}</h3>
          <span style={{ color: 'white' }}>{news[0].content}</span>
        </div>
      </div>
    </div>
  );

  // Style changes
  const prevButtonStyle = currentIndex === news.length - 1
    ? { cursor: 'not-allowed', opacity: 0.5 }
    : {};

  const nextButtonStyle = currentIndex === 0
    ? { cursor: 'not-allowed', opacity: 0.5 }
    : {};

  const handlePrev = () => {
    if (currentIndex === 0) return; // Prevent action at the start
    if (isTransitioning) return;
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(prev => prev - 1);
      setIsTransitioning(false);
    }, 300); // transition duration
  };

  const handleNext = () => {
    if (currentIndex === news.length - 1) return; // Prevent action at the end
    if (isTransitioning) return;
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(prev => prev + 1);
      setIsTransitioning(false);
    }, 300); // transition duration
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${hours}:${minutes} ${day}/${month}/${year}`;
  };

  // Render part
  return (
    <div className={'newsb news-inner-wrapper'}>
      <button
        ref={nextButtonRef}
        style={{
          right: '8.75%',
          bottom: '12.5%',
          ...prevButtonStyle // Apply the style changes
        }}
        className="big-button"
        onClick={handleNext}
        disabled={currentIndex === news.length - 1} // Disable the button at the end
      >
        <ChevronLeft />
      </button>

      <button
        style={{
          right: '2.75%',
          bottom: '12.5%',
          ...nextButtonStyle // Apply the style changes
        }}
        className="big-button"
        onClick={handlePrev}
        disabled={currentIndex === 0} // Disable the button at the start
      >
        <ChevronRight />
      </button>

      <div style={{ overflow: 'hidden', width: '83%', height: '85%', marginLeft: '25px', marginTop: '5px' }} onClick={() => navigate('/newsletter')}>
        <div className={`collection-detailsn ${isTransitioning ? 'transitioning' : ''}`}>
          <span style={{ color: 'red', fontSize: '12px', display: 'block' }}>{collection.type}</span>
          <h3 className="collection-titlen">{collection.name}</h3>
          <span style={{
            color: 'white',
            display: '-webkit-box',
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: '5px',
            position: 'relative'
          }}>
            {collection.content}
            <span style={{
              position: 'absolute',
              top: '70px',
              right: 0,
              width: '100%',
              height: '20px',
              pointerEvents: 'none'
            }}></span>
          </span>

          <span style={{ position: 'absolute', color: 'white', fontSize: '12px', right: '0', top: '0' }}>{formatDate(collection.publishedTime)}</span>
        </div>
      </div>

    </div>
  );
}

export default News;
