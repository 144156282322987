import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut, LucideBell, ExternalLink, XIcon } from 'lucide-react';
import { useCookies } from 'react-cookie';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Tooltip } from 'react-tooltip';
import './style.css';

const LogoutButton = ({ signOutUser, setLoggedIn, avatarUrl, isMobile }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState({});
  const [cookies, setCookies, removeCookies] = useCookies(['purchase']);
  const [isHovered, setIsHovered] = useState(false);
  const [userCredit, setUserCredit] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const NOTIFICATIONS_FETCH_INTERVAL = 12000000; //10000 - 10 seconds, 60000 - 1 minute, 12000000 - 200 minutes
  const navigate = useNavigate();
  const [notificationZIndex, setNotificationZIndex] = useState('9999');

  const markNotificationsAsRead = async () => {
    const unreadNotifications = notifications.filter(noti => !noti.read);
    if (unreadNotifications.length === 0) return;

    for (const noti of unreadNotifications) {
      try {
        const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/notifications/markAsRead/${noti.id}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
          },
        });

        if (!response.ok) {
          console.error('Failed to mark notification as read:', response.statusText);
        }
      } catch (error) {
        console.error('Error during marking notifications as read:', error);
      }
    }
  };

  const deleteAllNotifications = async () => {
    setShowNotifications(false);
    try {
      const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/notifications/delete`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // You can call fetchNotifications here to refresh the list after deletion
        fetchNotifications();
      } else {
        console.error('Failed to delete all notifications:', response.statusText);
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    }
  };

  const deleteNotification = async (notificationId) => {
    try {
      const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/notifications/delete/${notificationId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // You can call fetchNotifications here to refresh the list after deleting the specific notification
        fetchNotifications();
      } else {
        console.error('Failed to delete the notification:', response.statusText);
      }
    } catch (error) {
      console.error('Error during notification deletion:', error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/notifications/get', {
        headers: {
          'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
        setUnreadNotificationsCount(data.filter(noti => !noti.read).length);
      } else {
        console.error('Failed to fetch notifications:', response.statusText);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };


  useEffect(() => {
    if (cookies.username && cookies.accessToken) {
      // Immediately fetch notifications when the component mounts
      fetchNotifications();

      const intervalId = setInterval(fetchNotifications, NOTIFICATIONS_FETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }
  }, [cookies.username, cookies.accessToken]);

  useEffect(() => {
    if (cookies.username && cookies.accessToken) {
      fetchNotifications();
    }
  }, [cookies.username, cookies.accessToken]);

  useEffect(() => {
    if (!showNotifications) {
      const timer = setTimeout(() => {
        setNotificationZIndex('-999');
      }, 700);
      return () => clearTimeout(timer); // Clear the timeout if the component is unmounted
    } else {
      setNotificationZIndex('9999'); // reset zIndex when the dropdown is expanded
    }
  }, [showNotifications]);

  useEffect(() => {
    if (showNotifications)
      setIsExpanded(false);
    if (isExpanded) {
      setShowNotifications(false);
      setStyle({
        maxHeight: '100px',
        opacity: 1,
        transition: 'max-height 0.3s ease-in, opacity 0.3s ease-in',
      });
    } else {
      setStyle({
        maxHeight: '0',
        opacity: 0,
        transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
        overflowY: 'auto',
      });
    }
  }, [isExpanded, showNotifications]);

  const transitionStyles = {
    entering: { opacity: 1, maxHeight: '200px' },
    entered: { opacity: 1, maxHeight: '200px', overflow: 'hidden' },
    exiting: { opacity: 0, maxHeight: '0', overflow: 'hidden' },
    exited: { opacity: 0, maxHeight: '0', overflow: 'hidden' }
  };

  const buttonStyle = {
    position: "fixed",
    top: "10px",
    right: "10px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1px',
    paddingLeft: '3px',
    width: `${isMobile ? '120px' : '140px'}`,
    height: '40px',
    cursor: 'pointer',
    borderRadius: '10px',
    transition: 'all 0.1s',
    boxSizing: 'border-box',
    zIndex: '9999',
  };

  const notisStyle = {
    position: "fixed",
    top: "14px",
    right: "215px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1px',
    paddingLeft: '3px',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    borderRadius: '45%',
    border: '3px solid #181818',
    transition: 'all 0.1s',
    boxSizing: 'border-box',
    zIndex: '999999',
    overflowY: 'auto',
  };

  const logoutStyle = {
    position: "fixed",
    top: "14px",
    right: "170px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1px',
    paddingLeft: '3px',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    borderRadius: '45%',
    border: '3px solid #181818',
    transition: 'all 0.1s',
    boxSizing: 'border-box',
    zIndex: '999999',
    overflowY: 'auto',
  };

  const imageContainerStyle = {
    height: '34px',
    width: '34px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px',
    zIndex: '10000',
  };

  const textStyle = {
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'white',
    zIndex: '9999',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Added this line to center content horizontally
    width: '100%', // Added this line to ensure the container takes the full width of the button
    zIndex: '9999',
  };

  const dropdownStyle = {
    flexDirection: 'column', // Changed from row to column to stack the username and logout button
    alignItems: 'center', // Center content horizontally
    justifyContent: 'center', // Center content vertically
    backgroundColor: '#171717',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '10px',
    width: 'calc(100% + 4px)',
    marginLeft: '-1px', // Adjust the margin to account for the border
    marginTop: '-5px',
    ...style,
    zIndex: '10',
  };

  const notificationsDropdownStyle = {
    position: "fixed",
    ...dropdownStyle,
    borderTop: '3px solid #FCB800',
    top: '65px',
    maxHeight: "400px",
    minHeight: "100px",
    maxWidth: "275px",
    overflowY: 'auto',
    zIndex: '9999',
    right: '60px'
  };

  const expandCollapseTransition = {
    transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
  };

  const expandedNotificationsStyle = {
    ...notificationsDropdownStyle,
    ...expandCollapseTransition,
    opacity: 1,
  };

  const collapsedNotificationsStyle = {
    ...notificationsDropdownStyle,
    ...expandCollapseTransition,
    maxHeight: '0',
    opacity: 0,
    zIndex: notificationZIndex
  };


  const fetchUserCredit = async () => {
    try {
      const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/user/credit/${cookies.username}`, {
        headers: {
          'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUserCredit(data.credit); // Update userCredit state with received data
      } else {
        console.error('Failed to fetch user credit:', response.statusText);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  const handleLogout = () => {
    signOutUser(); // Call the signOutUser function passed as prop
    setLoggedIn(false); // Set loggedIn to false
  };

  useEffect(() => {
    fetchUserCredit();
  }, [cookies.purchase]);

  useEffect(() => {

    if (cookies.username && cookies.accessToken)
      fetchUserCredit();
  }, [cookies.username, cookies.accessToken]);

  const formatVolume = (volume) => {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
      formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
      formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', ''); // Remove trailing .0 if present
  };

  return (
    <>
      <button className="scaleOnHover" style={notisStyle} onClick={async () => {
        setIsExpanded(false);
        setShowNotifications(!showNotifications);
        if (!showNotifications) {
          setTimeout(async () => {
            await markNotificationsAsRead(); // Delay the marking as read by 1.5s.
          }, 1500);
        }
        fetchNotifications(); // Refresh the list of notifications every time the bell is clicked
      }}>
        <LucideBell size={16} color={'#181818'} style={{ marginRight: '2px', marginTop: '3px' }} />
        {
          unreadNotificationsCount > 0 && (
            <div style={{
              position: 'fixed',
              top: '10px',
              right: '212px',
              background: 'red',
              color: 'white',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              textAlign: 'center',
              lineHeight: '13px',
              fontSize: '10px',
            }}>
              {unreadNotificationsCount}
            </div>
          )
        }
      </button>

      <button className="scaleOnHover" style={logoutStyle}
        onClick={handleLogout}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <LogOut size={16} color={'#181818'} style={{ marginRight: '2px', marginTop: '3px', top:'10px', right: '150px' }} />
      </button>

      <div style={showNotifications ? expandedNotificationsStyle : collapsedNotificationsStyle}>
        {notifications.length === 0 &&
          <div key={-1} style={{ padding: '10px' }}>
            <span style={{ fontWeight: 'bold', color: '#FCB800' }}>
              No recent notifications
            </span>
            <p style={{ color: 'white', fontSize: '12px' }}>Stay active, follow projects, and explore the store!</p>
          </div>
        }
        {notifications.length > 0 &&
          <>
            <div style={{ padding: '0px', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', color: 'red' }}
              onClick={() => {
                // Call the deleteAllNotifications function here (needs to be implemented)
                deleteAllNotifications();
              }}>
              Clear All
            </div>
            <TransitionGroup>
              {notifications.map(noti => (
                <CSSTransition
                  key={noti.id}
                  timeout={500} // duration of the transition in milliseconds
                  classNames="notification-fade"
                >
                  <div style={{ ...transitionStyles, padding: '10px', position: 'relative' }}>
                    <Tooltip id="clearNotiInfo" border={false} style={{ userSelect: 'none' }} />
                    <Tooltip id="visitNotiInfo" border={false} style={{ userSelect: 'none' }} />
                    <XIcon data-tooltip-content="Clear Notification" data-tooltip-id="clearNotiInfo" size={18} style={{
                      position: 'absolute',
                      top: '13px',
                      right: '5px',
                      cursor: 'pointer',
                      color: '#FCB800',
                      zIndex: '99999',
                      userSelect: 'none'
                    }} onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // Call the deleteNotification function here (needs to be implemented)
                      deleteNotification(noti.id);
                    }} />
                    <span style={{ fontWeight: 'bold', color: '#FCB800' }}>
                      {!noti.read &&
                        <span style={{
                          display: 'inline-block',
                          width: '6px',
                          height: '6px',
                          borderRadius: '50%',
                          backgroundColor: '#FCB800',
                          marginRight: '8px',
                          verticalAlign: 'middle'
                        }}></span>
                      }
                      {noti.title}
                      {noti.navigate &&
                        <span style={{ marginTop: '-5px', marginLeft: '5px', fontSize: '10px', display: 'inline-block' }}>
                          <ExternalLink data-tooltip-content="Go to Page" data-tooltip-id="visitNotiInfo" style={{ cursor: 'pointer', userSelect: 'none' }} size={14} onClick={() => { navigate(noti.navigate) }} />
                        </span>
                      }
                    </span>
                    <p style={{ color: 'white', fontSize: '12px' }}>{noti.content}</p>
                    <span style={{ fontSize: '10px', color: 'gray' }}>{formatDate(noti.time)}</span>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </>
        }

      </div >

      <button className='button-header' style={buttonStyle} onClick={() => setIsExpanded(!isExpanded)}>
        <div style={contentStyle}>
          <span style={textStyle}>{`${formatVolume(userCredit)}`}<span style={{color: '#FCB800'}}> $WLD</span></span>
          <div style={imageContainerStyle}>
            <img src={avatarUrl} alt="User Avatar" style={{ width: '30px', height: '30px', borderRadius: '50%', border: '1px solid #FCB800' }} />
          </div>
        </div>
        <div style={dropdownStyle}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <span style={{ fontSize: "12px", color: "white", zIndex: '9999' }}>@{cookies.username?.length > 14 ? cookies.username.substring(0, 11) + "..." + cookies.username.substring(cookies.username.length - 3, cookies.username.length) : cookies.username}</span>
          </div>
        </div>
      </button>
    </>
  );
};

export default LogoutButton;

const formatDate = (time) => {
  const date = new Date(time);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${hours}:${minutes} ${day}/${month}/${year}`;
};