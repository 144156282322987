import React from 'react';

function Message({ text, size, category }) {
  const emojiRegex = /<(a)?:([a-zA-Z0-9\_]+):([a-zA-Z0-9\_]+)>/g;
  
  const cleanAndConvertText = (text) => {
    return text
      .replace(/<@[&!]?\d+>/g, '') // Remove user and role mentions
      .replace(/<#\d+>/g, '') // Remove channel mentions
      .replace(/@everyone/g, '') // Remove @everyone mentions
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert bold text
      .replace(/\n/g, '<br />'); // Convert newline characters to <br /> tags
  };

  const createEmojiElements = (text) => {
    let cleanedText = cleanAndConvertText(text);
    
    // Check if the text starts with "Congratulations" and replace it with the desired phrase
    if (cleanedText.toLowerCase().includes("congratulations")) {
      cleanedText = category == "games" ? "<em>Game winners announced! Open this message in Discord to check if you won.</em>" :" <em>Giveaway winners announced! Open this message in Discord to check if you won.</em>";
    }

    const segments = cleanedText.split(emojiRegex);
    const elements = [];

    for (let i = 0; i < segments.length; i += 4) {
      elements.push(<span key={i} dangerouslySetInnerHTML={{ __html: segments[i] }} />);
      if (i + 3 < segments.length) {
        const isAnimated = segments[i + 1] === 'a';
        const emojiName = segments[i + 2];
        const emojiId = segments[i + 3];
        const emojiUrl = `https://cdn.discordapp.com/emojis/${emojiId}.${isAnimated ? 'gif' : 'png'}`;
        elements.push(<img src={emojiUrl} alt={emojiName} key={i + 1} width={size} height={size} style={{ display: 'inline', verticalAlign: 'middle' }} />);
      }
    }

    return elements;
  };

  return <span>{createEmojiElements(text)}</span>;
}

export default Message;
