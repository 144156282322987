import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingPage from '../loading';
import { Pencil, Clipboard, Info, ArrowLeftCircle } from 'lucide-react'; // Import Save icon
import { useAlert } from 'react-alert';
import './style.css';
import { Tooltip } from 'react-tooltip';

function ProfilePage() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies();
    const { username } = useParams();
    const [userData, setUserData] = useState(null);
    const [displayName, setDisplayName] = useState("");
    const [userDescription, setUserDescription] = useState("");
    const [walletAddr, setWalletAddr] = useState("");
    const [userRank, setUserRank] = useState(0);
    const [error, setError] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [progress, setProgress] = useState(0);
    const lastClaimedDateKey = cookies.username + " lastClaimedDate";
    const lastClaimedDateValue = cookies[lastClaimedDateKey];
    const { sidebarExpanded } = cookies;
    const backButtonLeftMargin = sidebarExpanded === 'true' ? '300px' : '125px';
    const alert = useAlert();
    const isMobile = window.innerWidth <= 768;

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    };

    const fetchUser = async () => {
        return fetch(`https://wildones2023.oa.r.appspot.com/rest/user/${username}`, {
            headers: {
                'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
            },
        })
            .then(res => {
                if (!res.ok) {
                    if (res.status === 404) {
                        throw new Error('404');
                    } else {
                        throw new Error('500');
                    }
                }
                return res.json();
            })
            .then(data => {
                setUserData(data);
                setDisplayName(data.displayName);
                setUserDescription(data.description);
                setWalletAddr(data.walletAddr);
            })
            .catch(err => {
                setError(err.message);
            });
    };

    const fetchUserRank = async () => {
        try {
            const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/user/ranking/${username}`);
            if (!response.ok) {
                alert.error('Failed to fetch user rank');
            }
            const data = await response.json();
            setUserRank(data.ranking);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setCookie('activePage', 6, { path: '/' });
        const fetchData = async () => {
            await fetchUser();
            await fetchUserRank();
        };
        fetchData();
    }, [username, cookies.username, cookies.accessToken, setCookie, lastClaimedDateValue]);


    const handleSave = () => {
        // Validate the user description
        if (!validateInput(userDescription)) {
            alert.error('Description is not valid. Please ensure it has a maximum of 5 lines and each line has a maximum of 70 characters.');
            return; // Exit the function without saving
        }

        // Prepare the updated data
        const updatedData = {
            displayName: displayName,
            description: userDescription,
            walletAddr: walletAddr,
        };

        // Send the PUT request to update the user data
        fetch(`https://wildones2023.oa.r.appspot.com/rest/user/`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        })
            .then(res => res.text().then(text => {
                // If the response is not OK, show the error message
                if (!res.ok) {
                    throw new Error(text);
                }

                // Display the success message and fetch the updated user data
                alert.success(text);
                toggleEditMode();
                fetchUser();
            }))
            .catch(err => {
                // Display the error message
                alert.error(err.message);
            });
    };


    useEffect(() => {
        // This timeout is to ensure that the animation starts after the component is mounted
        const timeout = setTimeout(() => {
            if (userData) {
                const { progress } = getUserLevelAndProgress(userData.xp);
                setProgress(progress);
            }
        }, 100);

        return () => clearTimeout(timeout);
    }, [userData, lastClaimedDateValue]);


    if (error) {
        return <h1>{error}</h1>;
    }


    if (!userData) {
        return <LoadingPage />;
    }

    const isOwnProfile = cookies.username === username;
    const rgb = hexToRgb(userData.avatarDecoration); // Default to black if undefined
    const startColor = darkerWithMoreBlue(rgb);
    const middleColor = userData.avatarDecoration;
    const endColor = brighterWithLessRed(rgb);
    const { level, currentLevelXP, nextLevelXP } = getUserLevelAndProgress(userData.xp);

    return (
        <div className="profile-page">

            {!isOwnProfile && (
                <div className="back-button" style={{ left: backButtonLeftMargin }} onClick={() => navigate(-1)}>
                    <ArrowLeftCircle size={30} color={"#fab00a"} />
                </div>
            )}

            <div className="background-card" style={{ position: 'absolute', width: '70%', maxWidth: '800px', marginTop: '-15%', background: `linear-gradient(45deg, ${startColor}, ${middleColor}, ${endColor})` }}></div>
            <div className="user-profile" style={{ zIndex: '1', marginTop: `${isEditMode ? '20px' : '7.5%'}`, padding: `${isMobile ? '5px' : '20px'}`}}>
                <div style={{ position: 'relative', zIndex: '9' }}>
                    <img src={userData.avatarUrl} alt="User Avatar" className="user-avatar" />
                    <div className="xp-bar-container">
                        <div className="xp-info"> {currentLevelXP} / {nextLevelXP} XP</div>
                        <div className="xp-bar">
                            <div className="xp-bar-fill" style={{ width: `${progress}%`, background: `linear-gradient(45deg, ${startColor}, ${middleColor}, ${endColor})` }}></div>
                            <div className="xp-text" style={{ color: isColorBright(middleColor) ? '#000' : '#FFF' }}>{Math.round(progress)}%</div>
                        </div>

                        <span className="level-text" style={{ color: `${userData.avatarDecoration}` }}>Lv. {level}</span>
                    </div>

                    <div className="user-rank-container">
                        <span className="user-rank-text">COMMUNITY RANKING</span>
                        <Info color='gray' data-tooltip-content="Collect XP through daily activity and rewards" data-tooltip-id="UserRankInfo" size={20} style={{ cursor: 'help' }} />
                        <span className="user-rank-number" style={{ color: "white", marginLeft: "10px" }}>#{userRank}</span>
                        <Tooltip id="UserRankInfo" border={false} style={{ userSelect: 'none' }} />
                    </div>

                    <div className="user-info-card">
                        <div className="user-header">
                            <div className="header-content">
                                {isEditMode ? (
                                    <>
                                        <div className="input-group">
                                            <label htmlFor="displayName">Display Name:</label>
                                            <input
                                                style={{ marginBottom: '10px' }}
                                                id="displayName"
                                                type="text"
                                                value={displayName}
                                                onChange={(e) => setDisplayName(e.target.value)}
                                                className="edit-input"
                                            />
                                        </div>
                                        {isMobile && (
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <button onClick={handleSave} className="save-button-mobile">✔️</button>
                                                <button onClick={toggleEditMode} className="cancel-button-mobile">❌</button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="display-name-container">
                                        <h2 className="user-display-name">{userData.displayName}</h2>
                                        {userData.nftrole !== "Not Verified" && (
                                            <div className="badge" style={{
                                                background: getBadgeColor(userData.nftrole),
                                                color: isColorBright(dominantColorFromGradient(getBadgeColor(userData.nftrole))) ? '#000' : '#FFF',
                                            }}>
                                                {userData.nftrole}
                                            </div>
                                        )}
                                        {userData.gcrole !== "No GCRole" && (
                                            <div className="badge" style={{
                                                background: getBadgeColor(userData.gcrole),
                                                color: isColorBright(dominantColorFromGradient(getBadgeColor(userData.gcrole))) ? '#000' : '#FFF',
                                            }}>
                                                {userData.gcrole}
                                            </div>
                                        )}
                                        {userData.oneOfOneRoles && userData.oneOfOneRoles.length > 0 && userData.oneOfOneRoles.map((role, index) => (
                                            <div key={index} className="badge" style={{
                                                background: getBadgeColor(role),
                                                color: isColorBright(dominantColorFromGradient(getBadgeColor(role))) ? '#000' : '#FFF',
                                                marginBottom: '10px'
                                            }}>
                                                {role}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {isOwnProfile && !isEditMode && (
                                    <Pencil color='white' onClick={toggleEditMode} className="edit-icon" />
                                )}
                            </div>
                            <p className="user-username">@{userData.username}</p>
                            <div className="divider"></div>
                            {isEditMode ? (
                                <div className="input-group">
                                    <label htmlFor="userDescription">Description:</label>
                                    <textarea
                                        id="userDescription"
                                        value={userDescription}
                                        onChange={(e) => setUserDescription(e.target.value)}
                                        className="edit-textarea"
                                    />
                                </div>
                            ) : (
                                <p className="user-description" style={{ whiteSpace: 'pre-line' }}>
                                    {userData.description}
                                </p>
                            )}
                            {isEditMode ? (
                                <div className="input-group">
                                    <label htmlFor="walletAddr">Wallet Address:</label>
                                    <input
                                        id="walletAddr"
                                        type="text"
                                        value={walletAddr}
                                        onChange={(e) => setWalletAddr(e.target.value)}
                                        className="edit-input"
                                    />
                                </div>
                            ) : (
                                <span className="user-wallet-addr">
                                    {walletAddr.slice(0, 5)}...{walletAddr.slice(-4)}
                                    <Clipboard size={17.5} onClick={() => navigator.clipboard.writeText(walletAddr)} className="clipboard-icon" />
                                </span>
                            )}
                            <p className="user-last-seen">Last Seen: {getLastLoginText(userData.lastLogin)}</p>
                            {isEditMode && !isMobile && (
                                <div className="user-edit-options">
                                    <button onClick={handleSave} className="save-button">Save</button>
                                    <button onClick={toggleEditMode} className="cancel-button">Cancel</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;

function dominantColorFromGradient(gradient) {
    const matches = gradient.match(/#[a-fA-F0-9]{6}/g);  // This regular expression finds HEX colors
    return matches ? matches[0] : null;
}

function isColorBright(hex) {
    if (!hex || hex === '#') {
        return false;
    }

    let r, g, b;

    if (hex.length === 7) {
        r = parseInt(hex.substring(1, 3), 16);
        g = parseInt(hex.substring(3, 5), 16);
        b = parseInt(hex.substring(5, 7), 16);
    } else if (hex.length === 4) {
        r = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
        g = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
        b = parseInt(hex.substring(3, 4) + hex.substring(3, 4), 16);
    } else {
        return false;
    }

    // Luminance formula: 0.299 * R + 0.587 * G + 0.114 * B
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.7;
}

function validateInput(input) {
    const MAX_LINES = 5;
    const MAX_CHARS_PER_LINE = 70; // Adjusted based on your provided line

    const lines = input.split('\n');
    if (lines.length > MAX_LINES) {
        console.error(`Input has more than ${MAX_LINES} lines. Please provide ${MAX_LINES} lines or less.`);
        return false;
    }

    for (let line of lines) {
        if (line.length > MAX_CHARS_PER_LINE) {
            console.error(`One or more lines exceed the maximum of ${MAX_CHARS_PER_LINE} characters.`);
            return false;
        }
    }

    return true;
}

function getLastLoginText(lastLogin) {
    const currentTime = Date.now();
    const lastLoginTime = new Date(lastLogin.seconds * 1000 + lastLogin.nanos / 1000000).getTime();
    const differenceInSeconds = (currentTime - lastLoginTime) / 1000;

    if (differenceInSeconds < 60) return "moments ago";
    if (differenceInSeconds < 300) return "5 mins ago";
    if (differenceInSeconds < 600) return "10 mins ago";
    if (differenceInSeconds < 1800) return "30 mins ago";
    if (differenceInSeconds < 3600) return "1 hour ago";
    if (differenceInSeconds < 7200) return "2 hours ago";
    if (differenceInSeconds < 10800) return "3 hours ago";
    if (differenceInSeconds < 14400) return "4 hours ago";
    if (differenceInSeconds < 18000) return "5 hours ago";
    if (differenceInSeconds < 21600) return "6 hours ago";
    if (differenceInSeconds < 25200) return "7 hours ago";
    if (differenceInSeconds < 28800) return "8 hours ago";
    if (differenceInSeconds < 32400) return "9 hours ago";
    if (differenceInSeconds < 36000) return "10 hours ago";
    if (differenceInSeconds < 39600) return "11 hours ago";
    if (differenceInSeconds < 43200) return "12 hours ago";

    if (differenceInSeconds < 86400) return "less than a day ago";
    if (differenceInSeconds < 172800) return "a day ago";
    if (differenceInSeconds < 259200) return "less than 2 days ago";
    if (differenceInSeconds < 345600) return "less than 3 days ago";
    if (differenceInSeconds < 432000) return "less than 4 days ago";
    if (differenceInSeconds < 518400) return "less than 5 days ago";
    if (differenceInSeconds < 604800) return "less than a week ago";
    if (differenceInSeconds < 1209600) return "a week ago";
    if (differenceInSeconds < 1814400) return "more than a week ago";
    if (differenceInSeconds < 1814400) return "more than 2 weeks ago";
    if (differenceInSeconds < 2419200) return "more than 3 weeks ago";
    if (differenceInSeconds < 2628000) return "a month ago";
    return "more than a month ago";
}

function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
}

function rgbToString(r, g, b) {
    return `rgb(${r}, ${g}, ${b})`;
}

function brighterWithLessRed(rgb) {
    let [r, g, b] = rgb;
    r = Math.max(0, r - 30); // Decrease red by 30 units
    g = Math.min(255, g + 20); // Increase green slightly
    b = Math.min(255, b + 20); // Increase blue slightly
    return rgbToString(r, g, b);
}

function darkerWithMoreBlue(rgb) {
    let [r, g, b] = rgb;
    g = Math.max(0, g - 20); // Decrease green slightly
    b = Math.min(255, b + 30); // Increase blue by 30 units
    return rgbToString(r, g, b);
}

function getBadgeColor(role) {
    switch (role) {
        case "Wild Dragon":
            return "linear-gradient(45deg, red, pink)";
        case "Basilik":
            return "linear-gradient(45deg, gray, lightgray)";
        case "Komodo King":
            return "linear-gradient(45deg, lightgreen, green)";
        case "Chameleon":
            return "linear-gradient(45deg, green, darkgreen)";
        case "Chief Salamander":
            return "linear-gradient(45deg, orange, darkorange)";
        case "Lizard Soldier":
            return "linear-gradient(45deg, #BF40BF, #702963)";
        case "Outlaw Gecko":
            return "linear-gradient(45deg, #556B2F, gray)";
        case "Wild Monster":
            return "linear-gradient(45deg, pink, deeppink)";
        case "Wild One":
            return "linear-gradient(45deg, blue, deepskyblue)";
        case "GC Holder":
            return "linear-gradient(45deg, #ffba00, gold)";
        case "GC Master":
            return "linear-gradient(45deg, gold, goldenrod)";
        case "GC Whale":
            return "linear-gradient(45deg, #b9f2ff, #00ced1)";
        case "GC Dragon":
            return "linear-gradient(45deg, #858205, #525003)";
        case "Joker":
            return "linear-gradient(45deg, #5049ff, #3833b2)";
        case "Ancient":
            return "linear-gradient(45deg, #fdff5c, #b1b240)";
        case "Sketchpa":
            return "linear-gradient(45deg, #411c1c, #8d3c3c)";
        case "Baseball Player":
            return "linear-gradient(45deg, #99ec2f, #679f1f)";
        case "Basquiat":
            return "linear-gradient(45deg, #776c6c, #2a2626)";
        case "Mondrian":
            return "linear-gradient(45deg, #ff9c9c, #b26d6d)";
        case "Her Lizard":
            return "linear-gradient(45deg, #c300ae, #43003c)";
        default:
            return "transparent";
    }
}

const xpThresholds = [100, 105, 110, 115, 120, 126, 132, 138, 144, 151, 158, 165, 173, 181, 190, 199, 208, 218, 228, 239, 250, 262, 275, 288, 302, 317, 332, 348, 365, 383, 402, 422, 443, 465, 488, 512, 537, 563, 591, 620, 651, 683, 717, 752, 789, 828, 869, 912, 957, 1004, 1054, 1106, 1161, 1219, 1280, 1344, 1411, 1481, 1555, 1632, 1713, 1798, 1888, 1982, 2081, 2185, 2294, 2408, 2528, 2654, 2786, 2925, 3071, 3224, 3385, 3554, 3731, 3917, 4113, 4318, 4534, 4761, 4999, 5249, 5511, 5787, 6076, 6380, 6699, 7034, 7386, 7755, 8143, 8550, 8978, 9427, 9899, 10394, 10914];

function getUserLevelAndProgress(xp) {
    let totalXP = 0;
    let previousXPThreshold = 0; // This keeps track of the previous XP threshold

    for (let i = 0; i < xpThresholds.length; i++) {
        totalXP += xpThresholds[i];
        previousXPThreshold = totalXP - xpThresholds[i];
        if (xp < totalXP) {
            const xpForCurrentLevel = xp - previousXPThreshold;
            const xpRequiredForNextLevel = xpThresholds[i];
            const progress = (xpForCurrentLevel / xpRequiredForNextLevel) * 100;
            return { level: i + 1, progress: progress.toFixed(2), currentLevelXP: xpForCurrentLevel, nextLevelXP: xpRequiredForNextLevel };
        }
        // Update the previous XP threshold at the end of the loop
    }
    return { level: xpThresholds.length + 1, progress: 100, currentLevelXP: xp, nextLevelXP: xp };
}



