import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import LoadingPage from '../loading';
import { useParams, useNavigate } from 'react-router-dom';
import './style.css';
import { ChevronLeft, ChevronRight, Star, ArrowLeftCircle, XCircle } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

function ProjectPage() {
    const [cookies, setCookie] = useCookies();
    const [projectDetails, setProjectDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [collectionsData, setCollectionsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [isFavorite, setIsFavorite] = useState(false);
    const { sidebarExpanded } = cookies;
    const backButtonLeftMargin = sidebarExpanded === 'true' ? '300px' : '125px';
    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const API_KEY = 'ocnft_64e17337c50e9c276ea72c62';//process.env.REACT_APP_OCNFT_API_KEY;

    const toggleFavorite = async () => {
        try {
            const endpoint = isFavorite ? '/removeFromFavorites' : '/addToFavorites';
            const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/user${endpoint}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(projectDetails.id)   // Only send projectId
            });

            if (response.ok) {
                setIsFavorite(!isFavorite);  // Toggle the favorite state in UI
            } else {
                // Check if the response is JSON before trying to parse
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const result = await response.json();
                    console.error("Error toggling favorite status:", result);
                } else {
                    console.error("Error toggling favorite status. Server response:", await response.text());
                }
            }
        } catch (error) {
            console.error("Error toggling favorite status:", error);
        }
    };


    useEffect(() => {
        const updateItemsPerPage = () => {
            if (window.innerWidth <= 768) {
                setItemsPerPage(1);
            } else if (window.innerWidth <= 1000) {
                setItemsPerPage(2);
            } else if (window.innerWidth <= 1300) {
                setItemsPerPage(3);
            } else if (window.innerWidth <= 1700) {
                setItemsPerPage(4);
            } else {
                setItemsPerPage(5);
            }
        };

        window.addEventListener('resize', updateItemsPerPage);
        updateItemsPerPage(); // Initial set

        return () => window.removeEventListener('resize', updateItemsPerPage); // Cleanup
    }, []);

    const totalPages = Math.ceil(collectionsData.length / itemsPerPage);
    const { projectName } = useParams();
    const navigate = useNavigate();
    const [loadedImages, setLoadedImages] = useState({});

    const handleImageLoad = (id) => {
        setLoadedImages(prev => ({ ...prev, [id]: true }));
    };

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/projects/get');
                const allProjects = await response.json();

                // Filter the projects to find the one that matches the project name from the URL
                const matchedItem = allProjects.find(item => item.name === projectName);
                if (matchedItem) {
                    const projectResponse = await fetch(`https://api.opencnft.io/2/collection/${matchedItem.policyId}?expand=string`, {
                        headers: {
                            "X-Api-Key": API_KEY,
                        }
                    });
                    const projectData = await projectResponse.json();
                    setProjectDetails({ ...projectData, ...matchedItem });
                } else {
                    console.error("Project not found");
                    navigate('/search');
                }
                setLoading(false);
                const collectionsResponse = await fetch(`https://api.opencnft.io/2/collection/${matchedItem.policyId}/nfts`, {
                    headers: {
                        "X-Api-Key": API_KEY,
                    }
                });
                const collectionsData = await collectionsResponse.json();
                setCollectionsData(collectionsData.data);
            } catch (error) {
                console.error("Error fetching project details:", error);
                setLoading(false);
                navigate('/search'); // Navigate to /search if there's an error (e.g., project not found)
            }
        };

        fetchProjectDetails();
    }, [projectName, navigate]);

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                if (!projectDetails?.id) {
                    return;  // Exit if projectDetails hasn't been loaded
                }
                // Replace with your API endpoint and make sure to pass projectId as a query parameter
                const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/user/isFavorite?projectId=${projectDetails.id}`, {
                    headers: {
                        'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}` // Replace with user's actual token
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setIsFavorite(data.isFavorite);
            } catch (error) {
                console.error("Error checking favorite status:", error);
            }
        };

        checkFavoriteStatus();
    }, [projectDetails, cookies]);


    const paginatedItems = collectionsData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) {
        return <LoadingPage />;
    }

    return (
        <>
            <div className={`main-content ${isImageModalOpen ? 'blurred-background' : ''}`}>
                <div className="back-button" style={{ left: backButtonLeftMargin }} onClick={() => navigate(-1)}>
                    <ArrowLeftCircle size={30} color={"#fab00a"} />
                </div>

                <div className={`project-detail-container ${isImageModalOpen ? 'blurred-background' : ''}`}>

                    <div className="project-header">
                        <img src={projectDetails.imageUrl} alt={projectDetails.name} className="project-thumbnail" />
                        <div className="header-top" style={{ userSelect: 'none' }}>
                            <h1 className="project-title">{projectDetails.name}</h1>
                            <Star
                                className="star-icon"
                                data-tooltip-id="mark-fav"
                                data-tooltip-content={isFavorite ? "Unmark as Favorite" : "Mark as Favorite"}
                                fill={isFavorite ? '#fab00a' : '#000'}
                                onClick={toggleFavorite}
                            />
                            <Tooltip id="mark-fav" border={false} style={{ userSelect: 'none' }} />
                        </div>
                        <p className="project-description">{projectDetails.description}</p>
                    </div>
                    <div className="project-stats">
                        <div className="stat">
                            <span className="stat-value">{formatVolume(projectDetails.floor_price / 1000000)}</span>
                            <span className="stat-title">Floor</span>
                        </div>
                        <div className="stat">
                            <span className="stat-value">{formatVolume(projectDetails.total_volume / 1000000)}</span>
                            <span className="stat-title">Volume</span>
                        </div>
                        <div className="stat">
                            <span className="stat-value">{projectDetails.total_tx}</span>
                            <span className="stat-title">Transactions</span>
                        </div>
                        <div className="stat">
                            <span className="stat-value">{projectDetails.minted}</span>
                            <span className="stat-title">NFTs</span>
                        </div>
                        <div className="stat">
                            <span className="stat-value">{projectDetails.holders}</span>
                            <span className="stat-title">Holders</span>
                        </div>
                        <div className="stat">
                            <span className="stat-value">{formatVolume(projectDetails.highest_sale.price / 1000000)}</span>
                            <span className="stat-title">Highest Sale</span>
                        </div>
                    </div>
                    <div className="grid-container mb-1" style={{ marginLeft: "-5%", gridTemplateColumns: `repeat(${itemsPerPage}, 1fr)` }}>
                        {paginatedItems.map((collection, index) => {
                            const thumbnailUrl = typeof collection.thumbnail === 'string'
                                ? collection.thumbnail.replace(/^ipfs:\/\/(ipfs\/)?/, 'https://ipfs.io/ipfs/')
                                : '';
                            return (
                                <div style={{ width: "275px" }} className="item-card-wrapper" key={collection.fingerprint || index}>
                                    <div className="item-card">
                                        <div className="item-type">
                                            <span style={{ color: "red" }}>NFT</span>
                                        </div>
                                        {!loadedImages[collection.fingerprint] && <div className="loading-spinner"></div>} {/* Loading animation */}
                                        <img
                                            src={thumbnailUrl}
                                            alt={collection.last_metadata.name}
                                            className="item-image"
                                            onLoad={() => handleImageLoad(collection.fingerprint)}
                                            style={loadedImages[collection.fingerprint] ? {} : { display: 'none' }}
                                            onClick={() => {
                                                setSelectedImage(thumbnailUrl);
                                                setImageModalOpen(true);
                                            }}
                                        />

                                        <div className="item-details">
                                            <div className="item-info">
                                                <span className="item-name">{collection.last_metadata.name}</span>
                                                <span className="floor-price">Rarity Rank: {collection.rarity_rank}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="pagination">
                        <div className="chevron-container">
                            <ChevronLeft color='wheat'
                                className={currentPage === 1 ? 'disabled' : ''}
                                onClick={() => currentPage > 1 && setCurrentPage(prev => prev - 1)}
                            />
                        </div>
                        {Array.from({ length: totalPages }).map((_, index) => {
                            const pageNumber = index + 1;
                            return (
                                <span
                                    key={pageNumber}
                                    className={`page-number ${pageNumber === currentPage ? 'active' : ''}`}
                                    onClick={() => setCurrentPage(pageNumber)}
                                >
                                    {pageNumber}
                                </span>
                            );
                        })}
                        <div className="chevron-container">
                            <ChevronRight color='wheat'
                                className={currentPage === totalPages ? 'disabled' : ''}
                                onClick={() => currentPage < totalPages && setCurrentPage(prev => prev + 1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isImageModalOpen && (
                <div className="image-modal-overlay">
                    <div className="image-modal-content">
                        <img src={selectedImage} alt="Expanded" className="expanded-image" />
                        <button className="close-modal-btn" onClick={() => setImageModalOpen(false)}><XCircle size={35} /></button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ProjectPage;

const formatVolume = (volume) => {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
        formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
        formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', '') + ' ₳'; // Remove trailing .0 if present
};
