import React from 'react';
//import { SettingsPane, SettingsPage, SettingsContent, SettingsMenu } from 'react-settings-pane';
import './style.css';

function SettingsPageWO() {
    return (
        <div className="container">
            <video className="video-background" autoPlay loop muted>
                <source src="GifBackground.mp4" type="video/mp4" />
            </video>
            <div className="card">
                <h1>To Be Implemented</h1>
                <p>This section of our website is under construction.</p>
                <br></br>
                <p>If you have any suggestions to improve our website, please let us know! Thank you for your patience.</p>
                <a href="https://discord.com/channels/1040661298200977591/1166110788734435338" target="_blank" rel="noopener noreferrer">
                    Give suggestions!
                </a>
            </div>
        </div>
    );
}

export default SettingsPageWO;

/*function SettingsPageWO() {
    let settings = {
        'mysettings.general.name': 'Dennis Stücken',
        'mysettings.general.color-theme': 'purple',
        'mysettings.general.email': 'dstuecken@react-settings-pane.com',
        'mysettings.general.picture': 'earth',
        'mysettings.profile.firstname': 'Dennis',
        'mysettings.profile.lastname': 'Stücken',
    };

    // Define your menu
    const menu = [
        {
            title: 'General',          // Title that is displayed as text in the menu
            url: '/settings/general'  // Identifier (url-slug)
        },
        {
            title: 'Profile',
            url: '/settings/profile'
        }
    ];

    // Define one of your Settings pages
    const dynamicOptionsForProfilePage = [
        {
            key: 'mysettings.general.email',
            label: 'E-Mail address',
            type: 'text',
        },
        {
            key: 'mysettings.general.password',
            label: 'Password',
            type: 'password',
        }
    ];

    // Save settings after close
    const leavePaneHandler = (wasSaved, newSettings, oldSettings) => {
        // "wasSaved" indicates wheather the pane was just closed or the save button was clicked.

        if (wasSaved && newSettings !== oldSettings) {
            // do something with the settings, e.g. save via ajax.
        }
    };

    const settingsChanged = (changedSettings) => {
        // this is triggered onChange of the inputs
    };

    // Return your Settings Pane
    return (
        <SettingsPane items={menu} index="/settings/general" settings={settings} onPaneLeave={leavePaneHandler}>
            <SettingsMenu headline="General Settings" />
            <SettingsContent closeButtonClass="secondary" saveButtonClass="primary" header={true}>
                <SettingsPage handler="/settings/general">
                    <fieldset className="form-group">
                        <label for="profileName">Name: </label>
                        <input type="text" className="form-control" name="mysettings.general.name" placeholder="Name" id="general.ame" onChange={settingsChanged} defaultValue={settings['mysettings.general.name']} />
                    </fieldset>
                    <fieldset className="form-group">
                        <label for="profileColor">Color-Theme: </label>
                        <select name="mysettings.general.color-theme" id="profileColor" className="form-control" defaultValue={settings['mysettings.general.color-theme']}>
                            <option value="blue">Blue</option>
                            <option value="red">Red</option>
                            <option value="purple">Purple</option>
                            <option value="orange">Orange</option>
                        </select>
                    </fieldset>
                </SettingsPage>
                <SettingsPage handler="/settings/profile" options={dynamicOptionsForProfilePage} />
            </SettingsContent>
        </SettingsPane>
    )
}

export default SettingsPageWO;
*/