import './style.css';

function Top5Collections({ collectionsData }) {

  const formatVolume = (volume) => {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
      formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
      formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', '') + ' ₳'; // Remove trailing .0 if present
  };

  return (
    <div className="top5-collections">
      <ul>
        {collectionsData && collectionsData.slice(0, 5).map((collection, index) => {
          const thumbnailUrl = typeof collection.thumbnail === 'string'
            ? collection.thumbnail.replace(/^ipfs:\/\/(ipfs\/)?/, 'https://ipfs.io/ipfs/')
            : '';
          return (
            <li key={collection.id || index}
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(collection.link, '_blank')}>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <img src={thumbnailUrl ? thumbnailUrl : 'img-missing.jpg'} alt={collection.name} className="collection-thumbnail" />
                <img
                  style={{
                    position: 'absolute',
                    top: '-2.5px',
                    right: '-2.5px',
                    width: '25px',  // adjust as needed
                    height: '25px'  // adjust as needed
                  }}
                  src={`medal${index + 1}.svg`}
                />
              </div>
              <div className="collection-details">
                <h3 className="collection-title">{collection.name}</h3>
                <span>{formatVolume(collection.volume)}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Top5Collections;
