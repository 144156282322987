import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Search, ArrowDownAZ, ArrowUpAZ, Star } from 'lucide-react';
import LoadingPage from '../loading';
import './style.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function SearchPage() {
    const [cookies, setCookie] = useCookies(['activePage']);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');

    const filterRes = async (dataToFilter) => {
        if (!dataToFilter) dataToFilter = results;
        const filterResults = dataToFilter.filter(item => {
            if (query.length === 0) return true;
            return levenshtein(item.name.toLowerCase(), query.toLowerCase()) <= 3 ||
                item.name.toLowerCase().includes(query.toLowerCase()) ||
                query.toLowerCase().includes(item.name.toLowerCase());
        });

        const sortedResults = filterResults.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });

        setFilteredResults(sortedResults);
    };

    useEffect(() => {
        setCookie('activePage', 2, { path: '/' });

        const fetchResults = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/projects/get');
                const data = await response.json();
                setResults(data);
                filterRes(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        };

        fetchResults();

    }, []);


    useEffect(() => {
        filterRes();
    }, [query, sortOrder]);

    return (
        <div className='search-page'>
            {!loading && (
                <div className="search-container">
                    <Search className="search-icon" color='white' />
                    <input
                        type="text"
                        placeholder="Search..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="search-bar"
                    />
                    <Button className="order-button"style={{ marginLeft: "5px" }} onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}>
                        {sortOrder === 'asc' ? <ArrowDownAZ color='white' /> : <ArrowUpAZ color='white' />}
                    </Button>
                </div>
            )}
            {loading ? (
                <LoadingPage />
            ) : filteredResults.length ? (
                <div className="results-lists">
                    {filteredResults.map(item => <ItemCard key={item.name} item={item} />)}
                </div>
            ) : (
                <img src="/NoResults.png" alt="No Results" className="no-results-img" />
            )}
        </div>
    );
}


function ItemCard({ item }) {
    const [cookies, setCookie] = useCookies(['accessToken']);
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                if (!item?.id) {
                    return;
                }
                const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/user/isFavorite?projectId=${item.id}`, {
                    headers: {
                        'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setIsFavorite(data.isFavorite);
            } catch (error) {
                console.error("Error checking favorite status:", error);
            }
        };

        checkFavoriteStatus();
    }, [item, cookies]); // Removed isFavorite from dependencies

    const toggleFavorite = async (projectId, isFavorite) => {
        try {
            const endpoint = isFavorite ? '/removeFromFavorites' : '/addToFavorites';
            const response = await fetch(`https://wildones2023.oa.r.appspot.com/rest/user${endpoint}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${cookies.username} -:: ${cookies.accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(projectId)
            });

            if (response.ok) {
                setIsFavorite(!isFavorite);
            } else {
                console.error("Error toggling favorite status:", await response.text());
            }
        } catch (error) {
            console.error("Error toggling favorite status:", error);
        }
    };

    const handleFavoriteClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleFavorite(item.id, isFavorite);
    };

    return (
        <Link
            to={{
                pathname: `/project/${item.name}`,
                state: { item: item }
            }}
            className="card-link"
        >
            <Card style={{ width: '18rem', position: 'relative' }}>
                <Card.Title style={{ marginBottom: "15px", fontSize: "20px", color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {item.name}
                    <Star
                        className="star-icon"
                        data-tooltip-id="mark-fav"
                        data-tooltip-content={isFavorite ? "Unmark as Favorite" : "Mark as Favorite"}
                        style={{ cursor: 'pointer', marginTop: '2px', marginLeft: '10px' }}
                        fill={isFavorite ? '#fab00a' : '#000'}
                        onClick={handleFavoriteClick}
                    />
                </Card.Title>
                <Card.Img
                    variant="top"
                    src={item.imageUrl}
                    className="styled-image"
                />
                <Card.Body>
                    <Card.Text className="pt-4 pl-4 pr-4 truncate-text">
                        {item.description}
                    </Card.Text>
                </Card.Body>
            </Card>
            <Tooltip id="mark-fav" border={false} style={{ userSelect: 'none', zIndex: '9999' }} />
        </Link>
    );
}


export default SearchPage;

function levenshtein(a, b) {
    const matrix = [];
    let i, j;

    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
            }
        }
    }

    return matrix[b.length][a.length];
}