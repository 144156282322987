import React from 'react';
import './style.css';

function PCPage() {
  return (
    <div className="container">
      <video className="video-background" autoPlay loop muted>
        <source src="GifBackground.mp4" type="video/mp4" />
      </video>
      <div className="card">
        <h1>Platform has easier accessibility on desktop!</h1>
        <p>For a better user experience, we limited the platform to desktop use. Please navigate in your computer.</p>
        <br></br>
        <p><b>Meanwhile, keep updated with WildOnes:</b></p>
        <a href="https://discord.com/channels/1040661298200977591/1040665631328047244" target="_blank" rel="noopener noreferrer">
          Check what's new!
        </a>
      </div>
    </div>
  );
}


export default PCPage;
