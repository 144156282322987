import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Login from './pages/login';
import SidebarWrapper from './components/SidebarWrapper';
import HomePage from './pages/homepage';
import { useCookies } from "react-cookie";
import JoinDiscordPage from './components/JoinDiscordPage';
import CooldownPage from './components/CooldownPage';
import PCPage from './components/PCPage';
import SettingsPageWO from './pages/settings';
import ProjectPage from './pages/project';
import DashboardPage from './pages/dashboard';
import ProfilePage from './pages/profile';
import LoadingPage from './pages/loading';
import SearchPage from './pages/search';
import WildStore from './pages/wildstore';
import FavoritesPage from './pages/favorites';
import Leaderboard from './pages/leaderboard';
import NewsPage from './pages/news';
import { useAlert } from 'react-alert';
import './pages/popupStyles/styles.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  textTransform: 'none'
};


function App() {
  return (
    <Router>
      <AlertProvider template={AlertTemplate} {...options}>
        <AppContent />
      </AlertProvider>
    </Router >
  );
}

export default App;

function AppContent() {
  const [cookies, setCookies, removeCookies] = useCookies(['accessToken', 'username', 'sidebarExpanded', 'activePage']);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(!!(new URLSearchParams(location.search).get("sessionId")));
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isTooManyRequests, setIsTooManyRequests] = useState(false);
  const isLoggedIn = cookies.accessToken;
  const hasAttemptedLogin = cookies.accessToken !== undefined;
  const [showXPComponent, setShowXPComponent] = useState(true);
  const [animationClass, setAnimationClass] = useState('slide-in');
  const [hasFetchedUserData, setHasFetchedUserData] = useState(false);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  const initialIsVerified = storedUserData ? storedUserData.isVerified : false;
  const [isVerified, setIsVerified] = useState(initialIsVerified);
  const currentDate = new Date().toISOString().split('T')[0];
  const [canClaimXP, setCanClaimXP] = useState(false);
  const alert = useAlert();

  const claimDailyXP = async () => {
    try {
      const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/user/claimDailyXP', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${storedUserData.username} -:: ${cookies.accessToken}`,
        },
      });

      if (response.ok) {
        const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
        setCookies(storedUserData.username + " lastClaimedDate", currentDate, { path: "/" });

        alert.success('Successfully claimed daily XP!');
        setAnimationClass('slide-out');
        setTimeout(() => {
          setShowXPComponent(false);
        }, 500);
      } else if (response.status === 400) {
        const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
        setCookies(storedUserData.username + " lastClaimedDate", currentDate, { path: "/" });

        alert.error("You have already claimed your daily XP!");
        setAnimationClass('slide-out');
        setTimeout(() => {
          setShowXPComponent(false);
        }, 500);
      } else {
        alert.error('An error occurred while claiming daily XP. Please try again later');
        setAnimationClass('slide-out');
        setTimeout(() => {
          setShowXPComponent(false);
        }, 500);

      }
    } catch (e) {
      console.error(e);
      alert.error('An error occurred while claiming daily XP. Please try again later.');
      setAnimationClass('slide-out');
      setTimeout(() => {
        setShowXPComponent(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (isLoggingOut || hasFetchedUserData) return;

    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("sessionId");

    if (sessionId) {
      setIsLoading(true);
      fetch(`https://wildones2023.oa.r.appspot.com/rest/oauth/discord/retrieveToken?sessionId=${sessionId}`)
        .then(res => res.text())
        .then(accessToken => {
          if (accessToken) {
            setCookies("accessToken", accessToken, { path: "/" });
            fetchUserData(accessToken);
          }
        });
    } else {
      const storedUserData = JSON.parse(localStorage.getItem("userData"));
      if (storedUserData) {
        const avatarURL = storedUserData.avatar == null ?
          '/favicon.ico' :
          `https://cdn.discordapp.com/avatars/${storedUserData.id}/${storedUserData.avatar}.png`;
        const displayName = storedUserData.globalName || storedUserData.username;
        setCookies("displayName", displayName, { path: "/" });
        setCookies("avatarURL", avatarURL, { path: "/" });
        setCookies("username", storedUserData.username, { path: "/" });
        setIsVerified(storedUserData.isVerified);
      } else if (cookies.accessToken) {
        fetchUserData(cookies.accessToken);
      } else {
        setIsLoading(false);
      }
    }
  }, [location, isLoggingOut, cookies.accessToken, hasFetchedUserData]);

  useEffect(() => {
    if (cookies.username) {
      setCanClaimXP(!(cookies[cookies.username + " lastClaimedDate"] === currentDate));
    }
  }, [cookies.username]);

  const fetchUserData = async (accessToken) => {
    if (isLoggingOut) return;

    try {
      const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/oauth/discord/user', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response.status === 429) {
        setIsTooManyRequests(true);
        return;
      } else if (!response.ok) {
        console.error("Error fetching user data:", response.statusText);
        return;
      }
      setIsTooManyRequests(false);
      const userData = await response.json();
      localStorage.setItem("userData", JSON.stringify(userData)); // Store the entire user data
      const avatarURL = userData.avatar == null ?
        '/favicon.ico' :
        `https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.png`;
      const displayName = userData.globalName || userData.username;
      setCookies("displayName", displayName, { path: "/" });
      setCookies("avatarURL", avatarURL, { path: "/" });
      setCookies("username", userData.username, { path: "/" });
      setIsVerified(userData.isVerified); // Set the isVerified state directly from userData

      navigate('/dashboard');

    } finally {
      setIsLoading(false);
      setHasFetchedUserData(true);  // Set this to true after fetching user data
    }
  };



  async function signOutUser() {
    setIsVerified(false);
    setIsLoggingOut(true);
    removeCookies("accessToken", { path: "/" });
    removeCookies("avatarURL", { path: "/" });
    removeCookies("username", { path: "/" });
    removeCookies("sidebarExpanded", { path: "/" });
    removeCookies("activePage", { path: "/" });
    removeCookies("displayName", { path: "/" });
    removeCookies("purchase", { path: "/" });
    localStorage.removeItem("userData");
    setIsTooManyRequests(false); // Reset the React state
    setIsLoggingOut(false);
    navigate("/");
  }

  return (
    <>
      {window.innerWidth > 768 && isLoggedIn && isVerified && canClaimXP && showXPComponent && (
        <div
          className={`daily-quest-container ${animationClass}`}
          style={{
            position: 'absolute',
            right: '10%',
            top: '15px',
            width: '375px',
            height: '65px',
            backgroundColor: 'black',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            zIndex: '9999999999'
          }}
        >
          {/* SVG Icon */}
          <img src="/strongbox.svg" alt="Daily Quest Icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />

          {/* Text Section */}
          <div style={{ flex: 1 }}>
            <div style={{ color: 'white', fontWeight: 'bold' }}>Daily Quest</div>
            <div style={{ color: 'grey', fontSize: '14px' }}>Collect your daily XP bonus</div>
          </div>

          {/* Button */}
          <button
            className="hover-scale"
            onClick={claimDailyXP}
            style={{
              padding: '5px 10px',
              fontSize: '14px',
              cursor: 'pointer',
              backgroundColor: '#f7b400',
              border: 'none',
              borderRadius: '10px',
              fontWeight: 'bold'
            }}
          >
            Claim
          </button>

        </div>
      )}
      {!isLoading && <Login signOutUser={signOutUser} />}
      {isLoading ? (
        <LoadingPage />
      ) : (
        <Routes>

          {window.innerWidth > 768 && isLoggedIn && isVerified && (
            <>
              <Route path="/" element={<SidebarWrapper />}>
                <Route index element={<DashboardPage />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="favorites" element={<FavoritesPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="project/:projectName" element={<ProjectPage />} />
                <Route path="newsletter" element={<NewsPage />} />
                <Route path="leaderboard" element={<Leaderboard />} />
                <Route path="wildstore" element={<WildStore />} />
                <Route path="profile/:username" element={<ProfilePage />} />
                <Route path="profile" element={<Navigate to={`/profile/${storedUserData.username}`} />} />
                {/* <Route path="settings" element={<SettingsPageWO />} /> */}
                <Route path="*" element={<DashboardPage />} />
              </Route>
            </>
          )}

          {isTooManyRequests && (
            <>
              <Route path="/cooldown" element={<CooldownPage />} />
              <Route path="/*" element={<Navigate to="/cooldown" />} />
            </>
          )}

          {hasAttemptedLogin && !isVerified && (
            <>
              <Route path="/*" element={<Navigate to="/join" />} />
              <Route path="/join" element={<JoinDiscordPage />} />
              <Route path="/" element={<HomePage />} />
            </>
          )}

          {!hasAttemptedLogin && !isVerified && (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </>
          )}

          {window.innerWidth <= 768 && (
            <>
              <Route path="/pc" element={<PCPage />} />
              <Route path="/*" element={<Navigate to="/pc" />} />
            </>
          )}

        </Routes>
      )}
    </>
  );
}  