import React, { useEffect, useState } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import LoadingPage from '../../pages/loading';
import { useCookies } from 'react-cookie';

function Leaderboard() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    setCookie('activePage', 4, { path: '/' });
    setLoading(true);
    const currentTime = new Date().getTime();
    const expiryTime = 30 * 60 * 1000; // 30 minutes

    const cachedData = localStorage.getItem('leaderboardData');
    //CACHE TAKE OUT
    // if (cachedData) {
    //   const cachedLeaderboard = JSON.parse(cachedData);
    //   const age = currentTime - cachedLeaderboard.timestamp;

    //   if (age < expiryTime) {
    //     setPlayers(cachedLeaderboard.data);
    //     setLoading(false);
    //     return;
    //   }
    // }

    // Fetch the top players when the component mounts
    fetch('https://wildones2023.oa.r.appspot.com/rest/user/top10')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok', response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setPlayers(data);
        localStorage.setItem('leaderboardData', JSON.stringify({ data: data, timestamp: new Date().getTime() }));
        setLoading(false);
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        setLoading(false);
      });
  }, []);

  const topPlayers = players.slice(0, 3);
  const otherPlayers = players.slice(3);

  return (
    <div className='leaderboard-inner-wrapper'>
      <div className="leaderboard mt-3" style={{ width: '700px' }}>
        {loading ? <LoadingPage /> : <>
          <div className="top-players">
            {topPlayers.map((player, index) => (
              <Link to={`/profile/${player.username}`} key={player.username} className={`player-link ${index === 0 ? 'first' : index === 1 ? 'second' : 'third'}`}>
                <div className={`player-card ${index === 0 ? 'first' : index === 1 ? 'second' : 'third'}`}>
                  <img src='Crown.svg' alt="Crown" style={{ borderRadius: '0', position: 'absolute', marginTop: '-120px', zIndex: '1', display: `${index !== 0 ? 'none' : ''}` }} />
                  <img className='profile-pic' src={player.avatarUrl} alt={`${player.displayName}'s profile`} />
                  <img src={`medalstar${index + 1}.svg`} alt={`medal for ${index + 1} place`} className='medal' />
                  <h2>{player.displayName.split(" ")[0]}</h2>
                  <div>
                    {player.nftrole !== "Not Verified" && (
                      <div className="badge-lb" style={{
                        background: getBadgeColor(player.nftrole),
                        color: isColorBright(dominantColorFromGradient(getBadgeColor(player.nftrole))) ? '#000' : '#FFF',
                        fontSize: '10px'
                      }}>
                        {player.nftrole}
                      </div>
                    )}
                  </div>
                  <div>
                    {index === 0 || index === 1 ? (
                      <p>
                        {`Lv. ${getUserLevel(player.xp)}`}
                        <br />
                        <div>{formatXP(player.xp)}</div>
                      </p>
                    ) : (
                      <p style={{ marginTop: '5px' }}>
                        <p style={{ display: 'inline' }}>{`Lv. ${getUserLevel(player.xp)}`}</p> &nbsp;{formatXP(player.xp)}
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <ul className="other-players">
            {otherPlayers.map((player, index) => (
              <li key={player.username} style={{ width: '80%' }}>
                <Link to={`/profile/${player.username}`}>
                  <div className="list-item-contentl">
                    <span style={{ color: '#bbb' }}>{index + 4}.&nbsp;&nbsp;</span>
                    <img src={player.avatarUrl} alt={`${player.displayName}'s profile`} />
                    <span>{player.displayName}</span>
                    <span className="level-xp-container">
                      <span style={{ fontSize: '14px' }}>{`Lv. ${getUserLevel(player.xp)}`}</span>
                      <span style={{ fontSize: '10px' }}>{player.xp} xp</span>
                    </span>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </>
        }</div>
    </div>
  );
};


function dominantColorFromGradient(gradient) {
  const matches = gradient.match(/#[a-fA-F0-9]{6}/g);  // This regular expression finds HEX colors
  return matches ? matches[0] : null;
}

function isColorBright(hex) {
  if (!hex || hex === '#') {
    return false;
  }

  let r, g, b;

  if (hex.length === 7) {
    r = parseInt(hex.substring(1, 3), 16);
    g = parseInt(hex.substring(3, 5), 16);
    b = parseInt(hex.substring(5, 7), 16);
  } else if (hex.length === 4) {
    r = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
    g = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
    b = parseInt(hex.substring(3, 4) + hex.substring(3, 4), 16);
  } else {
    return false;
  }

  // Luminance formula: 0.299 * R + 0.587 * G + 0.114 * B
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.7;
}


function formatXP(volume) {
  let formattedVolume = volume.toString(); // Convert to string
  if (volume >= 1000000) {
    formattedVolume = (volume / 1000000).toFixed(1) + 'M';
  } else if (volume >= 1000) {
    formattedVolume = (volume / 1000).toFixed(1) + 'k';
  }
  return formattedVolume.replace('.0', '') + ' xp'; // Remove trailing .0 if present
};

const xpThresholds = [100, 105, 110, 115, 120, 126, 132, 138, 144, 151, 158, 165, 173, 181, 190, 199, 208, 218, 228, 239, 250, 262, 275, 288, 302, 317, 332, 348, 365, 383, 402, 422, 443, 465, 488, 512, 537, 563, 591, 620, 651, 683, 717, 752, 789, 828, 869, 912, 957, 1004, 1054, 1106, 1161, 1219, 1280, 1344, 1411, 1481, 1555, 1632, 1713, 1798, 1888, 1982, 2081, 2185, 2294, 2408, 2528, 2654, 2786, 2925, 3071, 3224, 3385, 3554, 3731, 3917, 4113, 4318, 4534, 4761, 4999, 5249, 5511, 5787, 6076, 6380, 6699, 7034, 7386, 7755, 8143, 8550, 8978, 9427, 9899, 10394, 10914];

function getUserLevel(xp) {
  let totalXP = 0;
  let previousXPThreshold = 0;

  for (let i = 0; i < xpThresholds.length; i++) {
    totalXP += xpThresholds[i];
    previousXPThreshold = totalXP - xpThresholds[i];
    if (xp < totalXP) {
      return i + 1;
    }
  }
  return xpThresholds.length + 1;
}

function getBadgeColor(role) {
  switch (role) {
    case "Wild Dragon":
      return "linear-gradient(45deg, red, pink)";
    case "Basilik":
      return "linear-gradient(45deg, gray, lightgray)";
    case "Komodo King":
      return "linear-gradient(45deg, lightgreen, green)";
    case "Chameleon":
      return "linear-gradient(45deg, green, darkgreen)";
    case "Chief Salamander":
      return "linear-gradient(45deg, orange, darkorange)";
    case "Lizard Soldier":
      return "linear-gradient(45deg, #BF40BF, #702963)";
    case "Outlaw Gecko":
      return "linear-gradient(45deg, #556B2F, gray)";
    case "Wild Monster":
      return "linear-gradient(45deg, pink, deeppink)";
    case "Wild One":
      return "linear-gradient(45deg, blue, deepskyblue)";
    case "GC Holder":
      return "linear-gradient(45deg, #ffba00, gold)";
    case "GC Master":
      return "linear-gradient(45deg, gold, goldenrod)";
    case "GC Whale":
      return "linear-gradient(45deg, #b9f2ff, #00ced1)";
    case "GC Dragon":
      return "linear-gradient(45deg, #858205, #525003)";
    case "Joker":
      return "linear-gradient(45deg, #5049ff, #3833b2)";
    case "Ancient":
      return "linear-gradient(45deg, #fdff5c, #b1b240)";
    case "Sketchpa":
      return "linear-gradient(45deg, #411c1c, #8d3c3c)";
    case "Baseball Player":
      return "linear-gradient(45deg, #99ec2f, #679f1f)";
    case "Basquiat":
      return "linear-gradient(45deg, #776c6c, #2a2626)";
    case "Mondrian":
      return "linear-gradient(45deg, #ff9c9c, #b26d6d)";
    case "Her Lizard":
      return "linear-gradient(45deg, #c300ae, #43003c)";
    default:
      return "transparent";
  }
}

function getBadgeMainColor(role) {
  switch (role) {
    case "Wild Dragon":
      return "red";
    case "Basilik":
      return "gray";
    case "Komodo King":
      return "lightgreen";
    case "Chameleon":
      return "green";
    case "Chief Salamander":
      return "orange";
    case "Lizard Soldier":
      return "#BF40BF";
    case "Outlaw Gecko":
      return "#556B2F";
    case "Wild Monster":
      return "pink";
    case "Wild One":
      return "blue";
    case "GC Holder":
      return "#ffba00";
    case "GC Master":
      return "gold";
    case "GC Whale":
      return "#b9f2ff";
    case "GC Dragon":
      return "#858205";
    default:
      return "transparent";
  }
}


export default Leaderboard;