import Hero from "../../components/HeroSection/Hero";
import ParentComponent from "../../components/TopCollections/topCollectionsData";
import FooterWO from '../../components/Footer/footer';
import './style.css';

function HomePage() {
  return (
    <>
      <div className="bg-neutral-900">
        <Hero />
        <ParentComponent />
        <FooterWO />
      </div>
    </>
  );
}

export default HomePage;
