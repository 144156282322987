import React, { useState, useEffect } from 'react';
import './style.css';

function LatestPurchases({ recordsData }) {

  const formatUTCDate = (timestampInSeconds) => {
    const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
    const year = date.getUTCFullYear();

    return `⌚ ${hours}:${minutes} ${day}/${month}/${year} UTC`;
  };

  const formatVolume = (volume) => {
    let formattedVolume = volume.toString(); // Convert to string
    if (volume >= 1000000) {
      formattedVolume = (volume / 1000000).toFixed(1) + 'M';
    } else if (volume >= 1000) {
      formattedVolume = (volume / 1000).toFixed(1) + 'k';
    }
    return formattedVolume.replace('.0', '') + ' $WLD'; // Remove trailing .0 if present
  };

  const [displayStates, setDisplayStates] = useState([]);
  const [transitionClasses, setTransitionClasses] = useState(new Array(recordsData.length).fill(''));


  useEffect(() => {
    setDisplayStates(recordsData.map(() => 'item'));
  }, [recordsData]);

  const handleClick = (index) => {
    const newStates = [...displayStates];

    const fadeElements = document.querySelectorAll('.record-display');
    fadeElements[index].classList.add('fade-out');

    setTimeout(() => {
      if (newStates[index] === 'item') {
        newStates[index] = 'price';
      } else if (newStates[index] === 'price') {
        newStates[index] = 'date';
      } else {
        newStates[index] = 'item';
      }

      setDisplayStates(newStates);

      fadeElements[index].classList.remove('fade-out');
      fadeElements[index].classList.add('fade-in');

      setTimeout(() => {
        fadeElements[index].classList.remove('fade-in');
      }, 200);

    }, 200);
  };


  return (
    <div className="latest-purchases">
      {recordsData && recordsData.map((record, index) => (
        <div key={index} onClick={() => handleClick(index)} className={`record-display ${transitionClasses[index]}`}>
          {displayStates[index] === 'item' && (
            <>
              <div className="item-image-wrapper-p">
                <img src={record.itemImageUrl} alt={record.itemName} width="50" style={{ borderRadius: '50%' }} />
                <button className={`item-type-p ${record.itemType === 'Whitelist' ? 'WL' : record.itemType}`}>{record.itemType == 'Whitelist' ? 'WL' : record.itemType}</button>
              </div>
              {record.itemName}
            </>
          )}
          {displayStates[index] === 'price' && `🪙 ${formatVolume(record.itemPrice)}`}
          {displayStates[index] === 'date' && formatUTCDate(record.purchaseDate.seconds)}
        </div>
      ))}
    </div>
  );
}

export default LatestPurchases;