import React, { useState, useEffect } from 'react';
import TopCollections from './TopCollections';

function ParentComponent() {
  const [collectionsData, setCollectionsData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('30d');
  const [fetchedPeriods, setFetchedPeriods] = useState({});
  const API_KEY = process.env.REACT_APP_OCNFT_API_KEY;

  useEffect(() => {
    if (fetchedPeriods[selectedPeriod]) {
      setCollectionsData(fetchedPeriods[selectedPeriod]);
      return;
    }

    fetch(`https://api.opencnft.io/2/market/rank/collection?time_range=${selectedPeriod}`, {
      headers: {
        'X-Api-Key': API_KEY,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const newRanking = data.ranking;
        setCollectionsData(newRanking);
        setFetchedPeriods({ ...fetchedPeriods, [selectedPeriod]: newRanking });
      })
      .catch((error) => {
        console.error('Error fetching collections:', error);
      });
  }, [selectedPeriod, fetchedPeriods]);

  return (
    <div className='TopCollections dropFadeInEffect'>
      <TopCollections collectionsData={collectionsData} selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
    </div>
  );
}

export default ParentComponent;
