import LoadingPage from '../loading';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../../App.css';
import { useCookies } from 'react-cookie';
import { LogIn } from 'lucide-react';
import LogoutButton from '../../components/LogoutButton';
import Ticker, { NewsTicker } from 'nice-react-ticker';
import './styles.css'

function Login({ signOutUser }) {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
  const [cookies] = useCookies([]);
  const [loggedIn, setLoggedIn] = useState(!!cookies.accessToken);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const avatarUrl = cookies.avatarURL;
  const [showTicker, setShowTicker] = useState(true);

  const handleLogin = async () => {
    setIsLoading(true); // Set loading to true when login is initiated

    try {
      const response = await fetch('https://wildones2023.oa.r.appspot.com/rest/oauth/discord/authURL');
      const result = await response.json();
      const authURL = result.authURL;
      window.location.href = authURL;

      const accessToken = cookies.accessToken;
      if (accessToken) {
        setLoggedIn(true);
        navigate("/dashboard");
      }

    } catch (error) {
      console.error("An error occurred while logging in:", error);

    }
  };

  useEffect(() => {
    setLoggedIn(!!cookies.accessToken); // Update loggedIn state when cookies.accessToken changes
  }, [cookies.accessToken]);

  if (isLoading) {
    return <LoadingPage />; // Display LoadingPage when isLoading is true
  }

  return (
    <div className="App bg-zinc-700">
      {/* <div>
        {showTicker && (
          <>
            <div className="newsticker" style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '999999999', width: '100%', height: '30px' }}>
              <Ticker isNewsTicker={true} visibleItems={1} slideSpeed={20}>
                <NewsTicker id="13" title="" />
                <NewsTicker id="1" title="⚠️ The platform is under development." meta="08:00:00 23/10/2023" />
                <NewsTicker id="2" title="Assets and design are provisory and may change." meta="08:00:00 23/10/2023" />
                <NewsTicker id="3" title="This is not the final version. " meta="08:00:00 23/10/2023" />
                <NewsTicker id="4" title="Thank you for your patience and support. ⚠️" meta="08:00:00 23/10/2023" />
                <NewsTicker id="5" title="" />
                <NewsTicker id="10" title="" />
              </Ticker>
            </div>


            <button onClick={() => setShowTicker(!showTicker)} style={{ fontSize: '12px', position: 'fixed', color: 'white', bottom: '5px', right: '0', zIndex: '9999999999' }}>
              Hide Warning
            </button>
          </>
        )}
      </div> */}
      <header className="App-header flex justify-between">
        {loggedIn ? (
          <LogoutButton
            signOutUser={signOutUser}
            setLoggedIn={setLoggedIn} // Pass setLoggedIn as prop
            navigate={navigate}
            avatarUrl={avatarUrl}
            isMobile={isMobile}
          />
        ) : (
          <>
            <button
              className="hover-scale"
              onClick={handleLogin}
              style={{
                position: "fixed", // Add this line
                top: "10px", // Add this line
                right: "10px", // Add this line
                backgroundColor: "#171717",
                color: "white",
                padding: "10px 20px",
                border: "3px solid #FCB800",
                borderRadius: "10px",
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                zIndex: "1000",
              }}
            >
              Login with Discord&nbsp;<LogIn size={20} />
            </button>
          </>
        )}
      </header>
    </div>
  );
}

export default Login;
