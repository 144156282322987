import React, { useEffect, useRef } from 'react';
import './Hero.css';
import radialImage from './radial.png';
import vector50 from './vector50.svg';
import vector51 from './vector51.svg';
import emailIcon from './email.svg'; // Import email icon
import twitterIcon from './twitterbird.svg'; // Import Twitter icon

function Hero() {
  const faviconRef = useRef(null);
  const favradialRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = window.scrollY;
      const rotation = (scrollPosition / maxScroll) * 30; // Calculate rotation angle (max 15 degrees)
      const scale = -((scrollPosition / maxScroll) *0.75) + 1;
      if (faviconRef.current) {
        faviconRef.current.style.transform = `rotate(${rotation}deg)`;
      }
      if (favradialRef.current) {
        favradialRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
  }, []);

  return (
    <div className='hero dropFadeInEffect'>
      <div className='radial-background' style={{ backgroundImage: `url(${radialImage})` }}></div>
      <div className='logo-container'>
        <img src='/logo/logo.png' alt='Logo' className='logo' />
      </div>
      <div className='hero-content'>
        <div className='hero-image-container'>
          <div className='hero-title'>
            <p><span className='highlighted'>Connect</span> with your favorite</p>
            <p>projects and don't miss</p>
            <p>any crucial information!</p>
          </div>
          <div className='vectors-container'>
            <img src={vector50} alt='Vector 50' className='vector50' />
            <img src={vector51} alt='Vector 51' className='vector51' />
          </div>
          <div className='follow-container'>
            <p className='follow-text'>Follow our media for more information</p>
            <div className='media-icons'>
              <a className='media-icon1' target='_blank' rel="noreferrer" href='mailto:...'><img src={emailIcon} alt='Email' /></a>
              <a className='media-icon1' target='_blank' rel="noreferrer" href='https://twitter.com/WildOnesNFTs'><img src={twitterIcon} alt='Twitter' /></a>
              <a className='media-icon' target='_blank' rel="noreferrer" href='https://www.jpg.store/collection/wildones'><img src="logo/jpgstore.png" alt='JPG Store' /></a>
            </div>
          </div>
        </div>
        <div className='favicon-container'>
          <img ref={faviconRef} src='/LOGO-WILDONES.png' alt='Favicon' className='favicon' />
          <div ref={favradialRef} className='favicon-radial' style={{ backgroundImage: `url(${radialImage})` }}></div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
