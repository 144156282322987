import React from 'react';
import './style.css';

function CooldownPage() {
  return (
    <div className="container">
      <video className="video-background" autoPlay loop muted>
        <source src="GifBackground.mp4" type="video/mp4" />
      </video>
      <div className="card">
        <h1>Woah, take it easy!</h1>
        <p>You were rate limited for making too many requests in a short span of time. While you wait, visit our latest discord announcements!</p>
        <br></br>
        <p><b>Please, sign out and comeback later.</b></p>
        <a href="https://discord.com/channels/1040661298200977591/1040665631328047244" target="_blank" rel="noopener noreferrer">
          Check what's new!
        </a>
      </div>
    </div>
  );
}


export default CooldownPage;
