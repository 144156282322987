import { ChevronLast, ChevronFirst, Settings } from "lucide-react";
import { useContext, createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const SidebarContext = createContext();

export default function Sidebar({ children, handleNavigation, activeItem }) {
  const [cookies, setCookie] = useCookies(["avatarURL", "sidebarExpanded", "displayName"]);
  const isMobile = window.innerWidth <= 768;
  if (!cookies.sidebarExpanded) setCookie('sidebarExpanded', isMobile ? "false" : "true", { path: '/' });
  const initialExpanded = cookies.sidebarExpanded === "true";
  const [expanded, setExpanded] = useState(initialExpanded);

  useEffect(() => {
    setCookie('sidebarExpanded', expanded.toString(), { path: '/' }); // Store as string
  }, [expanded, setCookie]);

  return (
    <aside style={{ zIndex: '9999999' }} className={`transition-all ${expanded ? (isMobile ? "w-48" : "w-64") : "w-16"} h-screen`}>
      <nav className="h-full flex flex-col bg-neutral-900 shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src="/logo/logo.png"
            className={`overflow-hidden transition-all ${expanded ? "w-24" : "w-0"
              }`}
            alt="WildOnes"
          />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className="p-1.5 rounded-lg bg-amber-400 hover:bg-amber-200"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3 border-t border-gray-400  mt-3 pt-3">{children}</ul>
        </SidebarContext.Provider>

        <div className="border-t flex p-3 border-gray-400">
          <img
            src={cookies.avatarURL}
            alt=""
            className="w-10 h-10 rounded-md"
          />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
          >
            <div className="leading-4">
              <h4 className="text-gray-200 font-semibold">{cookies.displayName}</h4>
            </div>
            {/* <button
              className={`p-2 rounded-md hover:bg-gray-50 hover:text-zinc-900 ${activeItem === 7
                ? "bg-amber-400 text-black"
                : "hover:bg-amber-100 hover:bg-opacity-20 text-white"
                }`}
              onClick={() => { handleNavigation(7, '/settings'); }}
            >
              <Settings color={expanded ? activeItem === 7 ? '#f3f3f3' : '#9ca3af' : 'currentColor'} size={20} />
            </button> */}

          </div>
        </div>
      </nav>
    </aside>
  )
}

export function SidebarItem({ icon, text, active, alert, onClick }) {
  const { expanded } = useContext(SidebarContext);

  return (
    <li
      className={`
        relative flex items-center py-2 px-3 my-1
        font-medium rounded-md cursor-pointer
        transition-colors group mt-5
        ${active
          ? "bg-amber-400 text-black"
          : "hover:bg-amber-100 hover:bg-opacity-20 text-white"
        }
    `}
      onClick={onClick}
    >
      {icon}
      <span
        className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"
          }`}
      >
        {text}
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-gray-400 ${expanded ? "" : "top-2"
            }`}
        />
      )}

      {!expanded && (
        <div
          style={{ zIndex: '9999' }}
          className={`
          absolute left-full rounded-md px-2 py-1 ml-6 
          bg-gray-300 text-gray-800 text-sm
          invisible opacity-20 -translate-x-3 transition-all
          group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 
      `}
        >
          {text}
        </div>
      )}
    </li>
  )
}