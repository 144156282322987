import { Twitter, Mail, Dribbble } from 'lucide-react';
import { Footer } from 'flowbite-react';
//import './style.css';

function FooterWO() {
    return (
        <Footer className="rounded-lg bg-white shadow dark:bg-neutral-800 md:flex md:items-center md:justify-between w-full p-6" >
            <div className="w-full">
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                    <div>
                        <Footer.Brand
                            alt="Wild Ones Logo"
                            href="#"
                            name=""
                            src="/logo/logo.ico"
                            style={{ width: '100px', height: '100px' }}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                        <div>
                            <Footer.Title title="about" className="text-gray-500 dark:text-white" />
                            <Footer.LinkGroup col className="text-gray-500 dark:text-white">
                                <Footer.Link href="#" className="hover:underline">
                                    WildOnes™
                                </Footer.Link>
                                <Footer.Link href="#" className="hover:underline">
                                    Community
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Follow us" className="text-gray-500 dark:text-white" />
                            <Footer.LinkGroup col className="text-gray-500 dark:text-white">
                                <Footer.Link href="#" className="hover:underline">
                                    Twitter
                                </Footer.Link>
                                <Footer.Link href="#" className="hover:underline">
                                    Discord
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Legal" className="text-gray-500 dark:text-white" />
                            <Footer.LinkGroup col className="text-gray-500 dark:text-white">
                                <Footer.Link href="#" className="hover:underline">
                                    Privacy Policy
                                </Footer.Link>
                                <Footer.Link href="#" className="hover:underline">
                                    Terms & Conditions
                                </Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider className="w-full my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright
                        by=" WildOnes™"
                        href="#"
                        year={2023}
                        className="text-sm text-gray-500 dark:text-gray-400 sm:text-center"
                    />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <Footer.Icon
                            href="#"
                            icon={Mail}
                            className="text-gray-500 dark:hover:text-white"
                        />
                        <Footer.Icon
                            href="#"
                            icon={Twitter}
                            className="text-gray-500 dark:hover:text-white"
                        />
                        <Footer.Icon
                            href="#"
                            icon={Dribbble}
                            className="text-gray-500 dark:hover:text-white"
                        />
                    </div>
                </div>
            </div>
        </Footer>);
    /*<footer>
        <div className="background">
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1600 900"
            >
                <defs>
                    <linearGradient id="bg" x2="0%" y2="100%">
                        <stop
                            offset="0%"
                            style={{ stopColor: "rgba(53, 127, 242, 0.6)" }}
                        ></stop>
                        <stop
                            offset="100%"
                            style={{ stopColor: "rgba(38, 89, 190, 0.06)" }}
                        ></stop>
                    </linearGradient>
                    <path
                        id="wave"
                        fill="url(#bg)"
                        d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
  s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z"
                    />
                </defs>
                <g>
                    <use xlinkHref="#wave" opacity=".3">
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="translate"
                            dur="8s"
                            calcMode="spline"
                            values="270 230; -334 180; 270 230"
                            keyTimes="0; .5; 1"
                            keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                            repeatCount="indefinite"
                        />
                    </use>
                    <use xlinkHref="#wave" opacity=".6">
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="translate"
                            dur="6s"
                            calcMode="spline"
                            values="-270 230;243 220;-270 230"
                            keyTimes="0; .6; 1"
                            keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                            repeatCount="indefinite"
                        />
                    </use>
                    <use xlinkHref="#wave" opacty=".9">
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="translate"
                            dur="4s"
                            calcMode="spline"
                            values="0 230;-140 200;0 230"
                            keyTimes="0; .4; 1"
                            keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                            repeatCount="indefinite"
                        />
                    </use>
                </g>
            </svg>
        </div>

        <section>
            <ul className="socials">
                <li><a className="fa-brands fa-facebook"></a></li>
                <li><a className="fa-brands fa-twitter"></a></li>
                <li><a className="fa-brands fa-linkedin"></a></li>
                <li><a className="fa-brands fa-instagram"></a></li>
            </ul>
            <ul className="links">
                <li><a>Home</a></li>
                <li><a>About</a></li>
                <li><a>Services</a></li>
                <li><a>Team</a></li>
                <li><a>Contact</a></li>
            </ul>
            <p className="legal">© 2023 All rights reserved</p>
        </section>
    </footer>
);*/
}

export default FooterWO;
